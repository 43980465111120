import {  makeAutoObservable, runInAction } from "mobx";

class DeliveryStore
{
    isLoading = true;
    error = false;
    Categories = [];
    Products = [];
    Cart = null;
    Addresses = [];
    SelectedAddress = null;
    DeliveryInformation = null;

    constructor(){
        makeAutoObservable(this)
        this.fillDefaultDeliveryProps();
    }


    fillDefaultDeliveryProps(){
        if (this.DeliveryInformation != null)
         return;
        this.DeliveryInformation = {
            customer: {
                name: null,
                phone: null,
                email: null,
            },
            
            payment: {
                type: 'cash',
                requierdMoneyChange: 0,
            },
            preorder: false,
            expeditionType: 'delivery',
            delivery: {
                address: {
                    city: {
                        name: null
                    },
                    street: {
                        name: null
                    },
                    houseNumber: null,
                    flatNumber: null,
                    floor: null,
                    entrance: null,
                    intercom: null,
                    coordinates: {
                        latitude: null,
                        longitude: null
                    }
                },
            },
            pickup: {
                taker: 'customer'
            },
            comment: "",
            personesQuantity: 0,
            
        }
    }

    //Остановить загрузку
    StopLoading() {
        runInAction(()=>{
            this.isLoading = false;
        });
    }   
    
    //получить меню
    async FetchMenu(){
        await this.FetchCategories();
        await this.FetchProducts();
        await this.getAddresses();
    }

    //Получить категории
    async FetchCategories(){
        try{
            const responce = await fetch("/api/delivery/menu/categories");
            const categories = await responce.json();
            runInAction(()=>{
                this.categories = categories;
            })
        }
        catch (error){
            console.error(error);
            this.error = true;
        }
    }

    //Получить товары
    async FetchProducts(categoryId){
        try{
            const url = `/api/delivery/menu/products` + (categoryId != null ? `?category=${categoryId}` : '');
            const responce = await fetch(url);
            const products = await responce.json();
            runInAction(()=>{
                this.products = products;
            })
        }
        catch (error){
            console.error(error);
            this.error = true;
        }
    }

    //Корзина
    async GetCart(){
        try{
            const responce = await fetch(`/api/delivery/cart`);
            if (responce.status === 200){
                const cart = await responce.json();
                runInAction(()=>{
                    this.Cart = cart;
                })
                return cart;
            }
            console.log(responce)
            return null
        }
        catch (error){
            console.error(error);
            this.error = true;
            return null;
        }
    }

    //Сумма в корзине
    GetCartSum(){
        if (this.Cart === null){
            return 0;
        } else{
            return this.Cart.sum;
        }
    }

    //Товар в корзине
    GetProductInCart(product_id){
        try{
            if (this.Cart === null || this.Cart.products === null){
                return null;
            }
            const product = this.Cart.products.find(x => x.product_id === product_id);
            if (product !== null)
                return product;
            else
                return null
        }
        catch{}
    }

    //Добавить в корзину
    async AddToCart(product){
        try{
            const params = {
                headers: {'Accept': 'application/json', 'Content-Type': 'application/json' },
                method: 'POST', 
                body: JSON.stringify(product)
            };
            const resp = await fetch('/api/delivery/cart/product', params); 
            if (resp.status === 200){
                this.GetCart();
                return true;
            }
            console.log(resp);
            return false;
        }catch{
             return false;
        }
        
    }

    //Обновить количество
    async UpdateQuantityOfProduct(cart_product, new_quantity){
        try{
            const params = {
                method: 'PUT', 
            };
            const resp = await fetch(`/api/delivery/cart/product?id=${cart_product.id}&quantity=${new_quantity}`, params); 
            if (resp.status === 200){
                this.GetCart();
                return true;
            }
            console.log(resp);
            return false;
        }catch{

        }
    }

    // - Корзина
    async minusCart(cart_product_id){
        const cart_product = deliveryStore.Cart.products.find(x => x.id === cart_product_id)
        if (cart_product != null){
            var new_quantity = cart_product.quantity - 1;
            this.UpdateQuantityOfProduct(cart_product, new_quantity);
        }
    }

    // + Корзина 
    async plusCart(cart_product_id){
        const cart_product = deliveryStore.Cart.products.find(x => x.id === cart_product_id)
        if (cart_product != null){
            var new_quantity = cart_product.quantity + 1;
            this.UpdateQuantityOfProduct(cart_product, new_quantity);
        }
    }

    //Первый продукт в корзине с заданным айди товара 
    getFirstProductInCart(product_id){
        const product = deliveryStore.Cart.products.find(x => x.product_id === product_id)
        if (product == null){
            return null;
        }
        return product;
    }


    //Найти товар по id
    GetProduct(product_id){
        const result = this.products.find(x => x.id === product_id);
        if (result === undefined || result === null){
            return null;
        }
        return result;
    }

    //Найти модификатор по id
    GetIngredient(product_id, ingredient_id){
        try{
            const product = this.products.find(x => x.id === product_id);
            if (product == null){
                return null;
            }
            if (product.ingredients != null && product.ingredients.length > 0){
                const  ingredient = product.ingredients.find(x => x.id === ingredient_id)
                if (ingredient !== undefined)
                    return ingredient;
            }
            if (product.ingredientsGroups != null && product.ingredientsGroups.length > 0){
                for (let i = 0; i < product.ingredientsGroups.length; i++) {
                    const ingGroup = product.ingredientsGroups[i];
                    if (ingGroup.ingredients != null && ingGroup.ingredients.length > 0){
                        const ingredient = ingGroup.ingredients.find(x => x.id === ingredient_id)
                        if (ingredient !== undefined)
                            return ingredient;
                    }
                }
                
            }
            return null;
        }catch(error){
            console.error(error)
            return null;
        }
        
    }

    //получение адреса
    async getAddresses(){
        try{
            const responce = await fetch(`/api/delivery/address`);
            if (responce.status === 200){
                const result = await responce.json();
                runInAction(()=>{
                    this.Addresses = result;
                })
                return result;
            } else{
                return null;
            }
        }catch (error){
            console.log(error)
            return null;
        }
    }

    //Добавление Адреса
    async addAddress(address){
        try{
            if (address == null)
                return null;
            const params = {
                    headers: {'Accept': 'application/json', 'Content-Type': 'application/json' },
                    method: 'POST', 
                    body: JSON.stringify(address)
                };
            const responce = await fetch(`/api/delivery/address`, params);
            console.log(responce)
            if (responce.status === 200){
                await deliveryStore.getAddresses();
                return true;
            } else{
                return false;
            }
        }catch (error){
            console.log(error)
            return false;
        }
    }

//Добавление Адреса
async deleteAddress(address){
    try{
        if (address == null)
            return null;
        const params = {
                method: 'DELETE', 
            };
        const responce = await fetch(`/api/delivery/address/${address.id}`, params);
        console.log(responce)
        if (responce.status === 200){
            if (this.SelectedAddress != null && this.SelectedAddress.id === address.id){
                this.SelectedAddress = null;
            }
            await deliveryStore.getAddresses();
            return true;
        } else{
            return false;
        }
    }catch (error){
        console.log(error)
        return false;
    }
}


    //получение адреса по строке
    async suggestAddresses(text){
        try{
            if (text == null || text.length <= 7)
                return null;
            const responce = await fetch(`/api/delivery/address/suggest?address=${text}`);
            if (responce.status === 200){
                const result = await responce.json();
                console.log(result);
                return result;
            } else{
                return null;
            }
        }catch (error){
            console.log(error)
            return null;
        }
    }

    getAddressString(address){
        if (address == null)
            return '<Неизвестный адрес>'
        var result = '';
        if (address.city != null && address.city.name != null && address.city.name.length > 0 ){
            result += address.city.name;
        }
        if (address.street != null && address.street.name != null && address.street.name.length > 0 ){
            result += ", " + address.street.name;
        }
        if (address.houseNumber != null && address.houseNumber > 0){
            result += ", д " + address.houseNumber;
        }
        if (address.flatNumber != null && address.flatNumber > 0){
            result += ", кв " + address.flatNumber;
        }
        return result;
    }

}

export const deliveryStore =  new DeliveryStore()