import React, {Component} from 'react';

export class Users extends Component{
    constructor(props){
        super(props);
        this.state ={
            users:[],
            focusedUser:null,
            modalTitle:"",
        }
        this.getClientsResult = true;
    }
    

    refreshList(){
        fetch('/api/client')
        .then(response=>response.json())
        .then(data =>{
            //data = data.filter(a => a.isOn === true);
            data.sort((a, b)=>a.id < b.id ? -1 : 1);
            this.setState({users:data});
            return true;
        })
        .catch(err => 
        {
            console.log(err);
            return false;
        })
        return true;
    }

    componentDidMount(){
        const result = this.refreshList();
        if (result === true){
            this.getClientsResult = true;
        } else{
            this.getClientsResult = false;
        }
        
    }

    changeUserName = (e) =>{
        this.setState({userName:e.target.value})
    }

    changePassword = (e) =>{
        this.setState({userPassword:e.target.value})
    }

    addClick(){
        this.setState({
            modalTitle:"Создать пользователя",
            userId:0,
            userName:"",
            userPassword:""
        })
    }

    editClick(user){
        this.setState({
            modalTitle:"Редактирование пользователя",
            focusedUser:user,
        })
    }

    createUser(){
        fetch('/api/client',{
            method:'POST',
            headers:{
                'Accept':'application/json',
                'Content-Type':'application/json',
            },
            body:JSON.stringify({
                name:this.state.userName,
                password:this.state.userPassword,
            })
        })
        .then(res=> res.ok ? res.text() : res.json())
        .then((result) => {
            alert(result);
            this.refreshList();
        },(error) =>{
            alert("Ошибка добавления");
        })
    }

        

    render(){
        const{
            
            users,
            modalTitle,
            focusedUser
        } = this.state;

        if (this.getClientsResult === false){
           return(
            <div>
                <h5 className='m-3' >Ошибка выполнения запроса</h5>
            </div>
           )
            
        } else

        return(
            
            <div>
               <h3>Сотрудники</h3> 
               <div>
                    <button type="button" className="btn btn-primary m-2 float-end" data-bs-toggle="modal" data-bs-target="#showUser" onClick={()=>this.addClick()}>
                        Создать
                    </button>



                    <table className='table table-stripped'>
                        <thead>
                            <tr>
                                <th>Код</th>
                                <th>Имя</th>
                                <th>Набор прав</th>
                                <th>Редактировать</th>
                            </tr>
                        </thead>
                        <tbody>
                            {users.map(user =>
                                <tr key={user.id}>
                                    <td>{user.id}</td>
                                    <td>{user.name}</td>
                                    <td>{user.rules}</td>
                                    {/* Кнопки редактировать */}
                                    <td>
                                        <button type="button" className="btn btn-light m-1 "
                                        data-bs-toggle="modal" 
                                        data-bs-target="#showUser" 
                                        onClick={()=>this.editClick(user)}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil" viewBox="0 0 16 16">
                                            <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
                                            </svg>
                                        </button>
                                        <button type="button" className="btn btn-light m-1 ">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash-fill" viewBox="0 0 16 16">
                                            <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z"/>
                                            </svg>
                                        </button>
                                    </td>
                                </tr>)}
                        </tbody>
                    </table>
                    
                    <div className="modal fade" id="showUser" tabIndex="-1" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered modal-lg modal-dialo-centered">
                            <div className="modal-content">
                                <div className='modal-header'>
                                    <h5 className="modal-title">{modalTitle}</h5>
                                    <button type="button" className="button-close" data-bs-dismiss="modal" aria-label="Закрыть"></button>
                                </div>
                                <div className="modal-body" >
                                    <div className="input-group mb-3">
                                        <span className="input-group-text">Имя</span>
                                        <input type="text" className="form-control" value={focusedUser != null ? focusedUser.name : null} onChange={this.changeUserName} ></input>
                                    </div>
                                    <div className="input-group mb-3">
                                        <span className="input-group-text">Пароль</span>
                                        <input id="" type="text" className="form-control" value={focusedUser != null ? focusedUser.password : null} onChange={this.changePassword} ></input>
                                    </div>
                                <div className='modal-footer'>
                                        {
                                            this.state.userId === 0 ? 
                                            <button className="btn btn-primary float-start " onClick={()=>this.createUser()}>Создать</button> : null
                                        }
                                        {
                                            this.state.userId !== 0 ? 
                                            <button className="btn btn-primary float-start ">Сохранить</button> : null
                                        }
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
               </div>
            </div>
        )
    }
}