import React, { useRef, useState } from 'react';
import styles from './DeliveryAddressess.module.scss'
import PropTypes from 'prop-types';
import { deliveryStore } from '../../stores/DeliveryStore';
import DeliveryAddressAdd from './DeliveryAddressAdd';
import { observer } from 'mobx-react-lite/dist';
import { toJS } from 'mobx';




export const DeliveryAddress = (props) => {
    const {address, isSelected, onSelected, onRemoved, index, ...otherProps} = props;



    const checked = (e) =>{
        const checked = e.target.checked;
        if (checked === true){
            onSelected(address);
        }

    }


    return(
        <div className={styles.radio_button_root} >
            <div {...otherProps} className={styles.radio_button}> 
                <input type='radio' id={`address-item-${index}`} defaultChecked={isSelected} name='radio-address' onChange={(e) => checked(e)}></input>
                <label htmlFor={`address-item-${index}`} >{deliveryStore.getAddressString(address)}</label>
                <img src='/images/controls/map_address_icon.png'></img>
                <div></div>
            </div>
            <img src='/images/controls/ic_close_1.png' className={styles.address_remove_button} onClick={()=>onRemoved(address)} ></img>
        </div>
        
    )

};

DeliveryAddress.defaultProps = {
    address : null,
    index : null,
    isSelected : false,
    onSelected : () => {},
    onRemoved : () => {},
}

DeliveryAddress.propTypes = {
    address: PropTypes.object,
    isSelected: PropTypes.bool,
    index: PropTypes.number,
    onSelected : PropTypes.func,
    onRemoved : PropTypes.func,
}




const DeliveryAddressess = observer((props) => {

    const { mode, onCloseClick, onSelectedClick, selectedAddressId, onAddClick, ...otherProps } = props;
    
    const [ addAddress, setAddAddress] = useState(false);
    
    const selectedAddress = useRef(deliveryStore.SelectedAddress != null ? deliveryStore.Addresses.find(x=>x.id == selectedAddressId) : null);

    const addressSelectedEvent = (address) =>{
        selectedAddress.current = address;
        
    }

    const addressRemoveEvent = async (address) =>{
        const result = await deliveryStore.deleteAddress(address);
        console.log(toJS(selectedAddress.current));
        if (result === true){
            if (selectedAddress.current != null && selectedAddress.current.id === address.id){
                selectedAddress.current = null;
            }
        }

    }

    const addressesList = () =>{

        const addresses = deliveryStore.Addresses;
        if (addresses == null || addresses.length === 0){
            return(
                <h2>Адресов для доставки нет. Вы можете добавить кнопкой +</h2>
            )
        } else{
            return(
                <div className={styles.addresses_panel}>
                        { addresses.map((x, index) =>
                         <DeliveryAddress 
                            onSelected={(address)=>addressSelectedEvent(address)} 
                            onRemoved={(address)=>addressRemoveEvent(address)}
                            key={x.id} 
                            isSelected={x.id == selectedAddressId}
                            address={x} 
                            index={index}></DeliveryAddress>)}
                        
                </div>
            )
        }
    }

    const selectedClicked = () =>{
        if (selectedAddress.current != null){
            const callback = () => onSelectedClick(selectedAddress.current);
            close(callback);
        }
        
    }

    const contentRef = useRef();
    const rootRef = useRef();

    const close = (callback)=>{
        try{
            contentRef.current.style.animation = null;
            rootRef.current.style.animation = null;
            contentRef.current.style.animation = `${styles.hide_content} .3s forwards`;
            rootRef.current.style.animation = `${styles.hide_root} .3s forwards`;
            setTimeout(()=>{
                onCloseClick();
                if (callback != null)
                    callback();
            }, 300)
            
        }
        catch
        {

        }
        
    }


    const bottomPanel = () =>{
        switch (mode){
            case 'select':
                return(
                <div className={styles.bottom_panel}>
                    <button className={styles.button_accept} onClick={() => selectedClicked()} >ВЫБРАТЬ</button>
                    <button className={styles.button_close} autoFocus onClick={ ()=> close() } >ОТМЕНА</button>
                </div>
                )
            case 'edit':
                return(
                    <div className={styles.bottom_panel}>
                        <button className={styles.button_close} autoFocus onClick={ ()=> close() }>ЗАКРЫТЬ</button>
                    </div>
                )
            default:
                return(
                    <div></div>
                )
        }
    }

    return (
        <div {...otherProps} className={styles.root} ref={rootRef}>  
            { !addAddress ? '' :
                <DeliveryAddressAdd onCloseClick={()=>setAddAddress(false)}></DeliveryAddressAdd>
            }
            <div className={styles.content} ref={contentRef}>
                <h1>Адреса доставки</h1>
                <button className={styles.button_add}  onClick={(e)=> setAddAddress(true)}>+</button>
                <div className={styles.center_panel}>
                    { addressesList() }
                </div>
                { bottomPanel() }
            </div>
        </div>
    );
});

DeliveryAddressess.defaultProps = {
    mode : 'edit',
    onAddClick : () => {},
    selectedAddressId : null,
    onSelectedClick : () => {},
    onCloseClick : () => {}
}

DeliveryAddressess.propTypes = {
    onAddClick : PropTypes.func,
    onSelectedClick : PropTypes.func,
    selectedAddressId : PropTypes.string,
    onCloseClick : PropTypes.func
}


export default DeliveryAddressess;