import React from 'react';
import styles from './DeliveryCartPreview.module.css'
import { deliveryStore } from '../../stores/DeliveryStore';
import { useNavigate } from 'react-router-dom';

const DeliveryCartPreview = () => {
    const navigate = useNavigate();

    const openCart = () =>{
        
        navigate('/delivery/cart')
    }

    if (deliveryStore.Cart == null || deliveryStore.Cart.products == null)
    {
        return(
            <div className={styles.root}>
                <h1>Ваш заказ</h1>
                <div className={styles.empty_message}>
                    <h2>Пусто</h2>
                    <h3>{'Давайте что нибудь закажем? :)'}</h3>
                </div> 
            </div>
        )
    } 
    else
    {
        return(
            <div className={styles.root}>
                <h1>Ваш заказ</h1>
                <div className={styles.products_list}>
                    {
                        deliveryStore.Cart == null || deliveryStore.Cart.products == null ? '' : 
                        deliveryStore.Cart.products.map(cart_product => {
                            return (
                            <DeliveryCartPreviewProduct key={cart_product.id}
                                product={deliveryStore.products.find(x=> x.id === cart_product.product_id)}
                                cart_product={cart_product}/>
                            )
                        })
                    }
                </div>
                <div className={styles.bottom}>
                    <button onClick={()=>openCart()}>ОФОРМИТЬ</button>
                </div>
            </div>
        )
    }
};

export default DeliveryCartPreview;

const DeliveryCartPreviewProduct = (props) =>{

    
    const {product, cart_product, ...otherProps} = props;
    
    if (product == null)
        return ;

    const imageBlock = () => {
        if (product.imageUrl == null){
            return (
                <img src="/images/photo_placeholder.png" alt='ph'></img>
            )
        } else{
            return(
                <img src={'/api/img/thmb/'+ product.imageUrl} alt={product.imageUrl} onError={({ currentTarget }) => {
                    currentTarget.onerror = null; 
                    currentTarget.src="/images/photo_placeholder.png";
                }}></img>
            )
        }
    }

    const cartMinusClick = (e, cart_product) =>{
        e.stopPropagation();
        deliveryStore.minusCart(cart_product.id)
    }

    const cartPlusClick = (e, cart_product) =>{
        e.stopPropagation();
        deliveryStore.plusCart(cart_product.id)
    }

    const ingredients = ()=>{
        if (cart_product.ingredients != null && cart_product.ingredients.length > 0)
        {
            const ingredients = cart_product.ingredients;
            return (
                <div className={styles.ingredients_list}>
                    {
                        ingredients.map((x,i) => 
                        {
                            const ingredient = deliveryStore.GetIngredient(product.id, x.ingredient_id)
                            if (ingredient != null){
                                return(
                                    <p key={x.id}  style={{fontSize:'11px', fontWeight:'400', opacity:'0.5'}} className={styles.ingredient}>
                                        {`${x.quantity}    x   ${ingredient.name}`}
                                    </p> 
                                )
                            } else{
                                return(
                                    <p key={x.id} className={styles.ingredient} style={{fontSize:'11px', fontWeight:'400', opacity:'0.5'}}>{'Ингредиент не найден'}</p>
                                )
                            }
                        })
                    }
                </div>
            )
        }
    }

    return (
        <div {...otherProps} className={styles.product_root}>
            { imageBlock()}
            <div className={styles.info}>
                <p>{product.name}</p>
                {
                    ingredients()
                }
                <div className={styles.cart_product_buttons}> 
                    <button onClick={(e) => cartMinusClick(e, cart_product)}>-</button>
                    <p onClick={(e) => {e.stopPropagation()}}>{cart_product.quantity}</p>
                    <button onClick={(e) => cartPlusClick(e, cart_product)}>+</button>
                </div>
            </div>
        </div>
    )

    
}
