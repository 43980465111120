import React from 'react';
import styles from './LoyaltyRegistration.module.scss'
import PhoneVerification from '../PhoneVerification';
import { useState } from 'react';
import BonusCardView from './BonusCardView';
import { useEffect } from 'react';
import BonusCardModal from './BonusCardModal';
import { PersonalInforamtion } from './personal_information'
import { observer } from 'mobx-react-lite';
import  registrationStore  from '../../stores/LoyaltyRegistrationStore'
import {BeatLoader} from 'react-spinners'

import regFromCheckBox from '../../CSS/WalletRegCheckbox.module.css'
import { createRef } from 'react';
import GlobalStore from '../../stores/GlobalStore';

const LoyaltyRegistration = observer((props) => {

    const regStore = registrationStore;

    const [verifyData, setVerifyData] = useState(null);
    const [modalCard, setModalCard] = useState(null)
    // const [connectionError, setConnectionError] = useState(false);
    const [regisration, setRegistration] = useState(null)

    const contentRef = createRef();

    useEffect(()=>{
        const fetch = async () =>{
            await regStore.refreshCardsList();
            regStore.stopLoading();
        };
        fetch();
    },[]);

    const onPhoneChange = (e) => {
        if (regStore.checkPhoneCorrect(e.target.value) !== true){
            if(regStore.isPhoneCorrect === true){
                regStore.setIsPhoneCorrect(false);
                return;
            }   
        } else{
            if (regStore.isPhoneCorrect === false)
            regStore.setIsPhoneCorrect(true);
        }
    }

    const onAcceptClick = (e) =>{
        const input = document.getElementById('phone-input');
        regStore.clientPhone = input.value;
        regStore.checkUserRegestration();
    }

    const onBackClick = (e) =>{
        regStore.clientPhone = null;
        regStore.setClientRegistrationStatus(null);
        regStore.setIsPhoneCorrect(false);
        // setCodeValue(['','','','']);
    }

    //#region VerifyNumber

    const verifyAccept = async (code) =>{
        console.log(code);
        const {result, message} = await regStore.verifyPhoneByCode(regStore.clientPhone, code)
        if (result){
            regStore.loading = true;
            await regStore.refreshCardsList();
            regStore.loading = false;
        } else
        {
            regStore.showMessage(message)
        }
        
    }

    const verifyClose = () =>{
        console.log('close_invoke')
        setVerifyData(null);
    }
    //#endregion



    const openCardModal = (wallet, card) =>{
        console.log(wallet);
        console.log(card);
        const c = {
            wallet: wallet,
            card: card,
        }
        setModalCard(c);
    }

    //Инициализация звонка клиенту
    const authByPhone = async () =>{
        try
        {
            const resp = await fetch(`api/client/auth?phone=${regStore.clientPhone}`, { method:'POST'});
            if (resp.status === 200){
                //const json = await resp.json();
                const verData = {
                    phone: regStore.clientPhone,
                }
                setVerifyData(verData);
            } 
            else
            {
                const result = await resp.json();
                regStore.showMessage(result.message);
            }
        }
        catch(error)
        {
            console.error(error);
        }
        
    }

    //Выход по телефону
    const logoff = async () =>{
        try
        {
            const resp = await fetch(`api/client/auth`, { method:'DELETE'});
            if (resp.status === 200){
                //const json = await resp.json();
                regStore.clientPhone = null;
                setVerifyData(null);
                regStore.clientCards = null;
                setModalCard(null);
                regStore.setClientRegistrationStatus(null);
                regStore.setIsPhoneCorrect(false);
            } 
            else
            {
                const result = await resp.json();
                regStore.showMessage(result.message);
            }
        }
        catch(error)
        {
            console.error(error);
        }
        
    }


    const beginRegistration = () =>{
        setRegistration({phone : regStore.clientPhone})
    }

    const beginResitrateNewClient = async () =>{
        try{
            const pers_checkbox = document.getElementById('allowpersonalinfo');
            if (pers_checkbox.checked !== true){
                regStore.showMessage('Примите согласие на обработку перс.данных')
                return;
            }
            const name_element = document.getElementById('clientname')
            const phone_element = document.getElementById('clientphone')
            const email_element = document.getElementById('clientemail')
            const birthday_element = document.getElementById('clientbirthday')
            const client = {
                name: name_element.value,
                phone: phone_element.value,
                email: email_element.value,
                birthday: birthday_element.value,
            }
            const json = await JSON.stringify(client);
            const validate_info = await fetch('/api/client/validate_clientinfo', 
            { method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: json, 
            });
            if (validate_info.status !== 200){
                regStore.showMessage(await validate_info.text());
                return;
            } else{
                //Отправка звонка
                const resp = await fetch(`api/client/auth?phone=${regStore.clientPhone}`, { method:'POST'});
                console.log(resp.status);
                if (resp.status === 200){
                    regStore.clientFormData = client;
                    console.log("OK");
                } 
                else
                {
                    const result = await resp.json();
                    console.error(resp.status)
                    console.error(result)
                    regStore.showMessage(result.message);
                }
                
            }
        }
        catch (error){
            console.error(error)
        }
        
    }

    const finishRegNewClient = async (code) =>{
        const {result, message} = await regStore.verifyPhoneByCode(regStore.clientPhone, code);
        if (result){
            const client = regStore.clientFormData;
            if (client == null)
                return;
            const json = await JSON.stringify(client);
            const responce = await fetch('/api/client/wallet', 
            { method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: json, 
            });
            if (responce.status !== 200){
                regStore.showMessage(await responce.text());
                return;
            } else{
                await regStore.refreshCardsList();
                setRegistration(null);
                regStore.clientFormData = null;

            }
        } else{
            console.error(message);
        }
    }

    //render
    const phoneInputBlock = () =>{
        return (
            <div ref={contentRef} className={styles.contnet_phone}>
                <div className={styles.top}>
                    <h3>Программа лояльности</h3>
                </div>
                <div className={styles.center}> 
                    {/* <div className={styles.phone_enter_title}>Номер телефона</div>
                    <div className={styles.phone_container}>
                        <div className={styles.phone_prefix}>+7</div>
                        <input id='phone'  className={styles.phone} autoComplete="off" type='text' inputMode="numeric" placeholder={'900-000-00-00'} maxLength={10}></input>
                    </div>  */}
                    <div className={styles.input_phone}>
                        
                        <input autoFocus type='phone' id='phone-input' required onChange={(e)=>onPhoneChange(e)}
                            autoComplete="off" inputMode="numeric"  maxLength={10} placeholder='Телефон'></input>
                            
                        <label htmlFor='phone-input'>Телефон</label>
                        <p>+7</p>
                    </div>
                </div>
                <div  className={styles.bottom}>
                    <button id='phoneaccept' disabled={!regStore.isPhoneCorrect} autoFocus={true} className={styles.button_accept} onClick={(e)=>onAcceptClick(e)}>Подтвердить</button>
                </div>
            </div>
        )
    }
    
    const phoneEntered = ()=>{

        switch (regStore.clientRegistrationStatus){
            case 'NotFound' :
                return (
                    <div className={styles.content}>
                        <div className={styles.top}>
                            <button className={styles.back_button} style={{filter: GlobalStore.Theme == 'light' ? 'invert(100%)' : ''}} onClick={(e)=>onBackClick(e)}>
                                <img src='images/controls/ic_arrow_back.png' alt='back'></img>
                            </button>
                        </div>
                        <div className={styles.center}> 
                            <h1 >{'Создадим новую карту?'}</h1>
                            <h3>{'* Потребуется верификация по номеру телефона'}</h3>
                            <h3>{'* Карту можно добавить в AppleWallet или GooglePay'}</h3>
                        </div>
                        <div className={styles.bottom}>
                            
                            <button className={styles.button_accept} onClick={() => beginRegistration()}>Приступить</button>
                        </div>
                    </div>
                )
            case 'NotRegistered' :
                return (
                    <div className={styles.content}>
                        <div className={styles.top}>
                            <button className={styles.back_button} style={{filter: GlobalStore.Theme == 'light' ? 'invert(100%)' : ''}} onClick={(e)=>onBackClick(e)}>
                                <img src='images/controls/ic_arrow_back.png' alt='back'></img>
                            </button>
                        </div>
                        <div className={styles.center}> 
                            <h1>{'По номеру есть карта лояльности'}</h1>
                            <h3>{'* Если у вас нет доступа к номеру телефона, обратитесь к сотрудникам заведения с пластиковой картой'}</h3>
                            <h3>{'* Карту можно добавить в AppleWallet или GooglePay'}</h3>
                            <h3>{'* После регистрации пластиковая карта будет заблокирована и недоступна, остаток с карты перенесем на новую'}</h3>
                            <h3>{'* Если карта была выпущена в приложении доставки или в другом приложении вы получите доступ к ней'}</h3>
                        </div>
                        <div className={styles.bottom}>
                            <button className={styles.button_accept} onClick={authByPhone} disabled={regStore.requestMessage !== null}>Приступить</button>
                        </div>
                    </div>
                )
            case 'Registered' :
                return (
                    
                    <div className={styles.content}>
                        <div className={styles.top}>
                            <button className={styles.back_button} style={{filter: GlobalStore.Theme == 'light' ? 'invert(100%)' : ''}} onClick={(e)=>onBackClick(e)}>
                                <img src='images/controls/ic_arrow_back.png' alt='back'></img>
                            </button>
                        </div>
                        <div className={styles.center}> 
                            <h1>{'Вы уже получали карту лояльности'}</h1>
                            <h3>{'* Можете восстановить к ней доступ по номеру телефона'}</h3>
                            <h3>{'* Без номера телефона мы не сможем удостовериться, что вы являетесь владельцем карты'}</h3>
                        </div>
                        <div  className={styles.bottom}>
                            <button className={styles.button_accept} onClick={authByPhone} disabled={regStore.requestMessage !== null} >Восстановить</button>
                        </div>
                    </div>
                )
            default :
                return (
                    <div className={styles.content}>
                        <div className={styles.top}>
                            <button className={styles.back_button} style={{filter: GlobalStore.Theme == 'light' ? 'invert(100%)' : ''}} onClick={(e)=>onBackClick(e)}>
                                <img src='images/controls/ic_arrow_back.png' alt='back'></img>
                            </button>
                        </div>
                        <div className={styles.center}> 
                            <h1>{'Сервер недоступен'}</h1>
                            <h2 style={{marginBottom:'30px'}}>{'Сообщите администратору или попробуйте еще раз позже. Приносим свои извинения'}</h2>
                        </div>
                        
                    </div>
                )
        }
    }

    const verifyComponent = () =>{
        if (verifyData){
            return (
                <div className={styles.content}>
                    <PhoneVerification verifyData={verifyData} onAccept={(code)=>verifyAccept(code)} onClose={()=>verifyClose()}/>
                </div>
            )}
    }

    //Нет подключения
    // if (connectionError === true){
    //     return(
    //         <div className={styles.root}>
    //             <div className={styles.cards_list}>
    //                 <div>Нет соединения сервером. Приносим свои извинения</div>
    //             </div>
                
    //         </div>
    //     )
    // }

    if (regStore.loading === true){
        return (
            <div className={styles.root}>
                <BeatLoader
                    color='var(--app-accent-color)' 
                    speedMultiplier={1.5}
                    loading={true} size={20} aria-label={'loading'} ></BeatLoader>
            </div>
        )
    }


    const closeVerifyReg = () =>{
        regStore.clientFormData = null;
    }


    const PhoneVerificationModal = () =>{
        if (regStore.clientFormData !== null){
            return (
                <div className={styles.modal_root}>
                    <div className={styles.modal_content}>
                        <PhoneVerification onClose={closeVerifyReg} onAccept={(code) => finishRegNewClient(code)}/>
                    </div>
                    
                </div>
            )
        }
        
    }

    const TopMessage = () =>{
        if (regStore.requestMessage != null){
            return (
                <div className={styles.top_message}>
                    <p>{regStore.requestMessage}</p>
                </div>
            )
        }
        
    }

    if (regisration != null){
        return (
            <div className={styles.root} style={{paddingTop:'40px'}}>
                { TopMessage()}
                { PhoneVerificationModal() }
                <div className={styles.cards_list}>
                    <h2 >Регистрация</h2>
                    <form className={styles.reg_form}>

                        <div className={styles.input_simple}>
                            <input type='email' id='clientname' placeholder='Электронная почта' required></input>
                            <label htmlFor='clientname'>Имя</label>
                        </div>
                        
                        <div className={styles.input_phone2}>
                            <input  type='phone' id='clientphone' required
                                autoComplete="off" inputMode="numeric" defaultValue={regisration.phone} value={regisration.phone}  maxLength={10} placeholder='Телефон'></input>
                                
                            <label htmlFor='clientphone'>Телефон</label>
                            <p>+7</p>
                        </div>

                        <div className={styles.input_simple}>
                            <input type='email' id='clientemail' placeholder='Электронная почта' required></input>
                            <label htmlFor='clientemail'>Почта</label>
                        </div>

                        <div className={styles.input_simple}>
                            <input type='date' defaultValue="2023-11-27"  id='clientbirthday' placeholder='Электронная почта' required></input>
                            <label htmlFor='clientbirthday'>Дата рождения</label>
                        </div>

                        {/* <label htmlFor='clientname' className={styles.reg_form_label}>Имя</label>
                        <input id='clientname' type='name' className={styles.name} />
                        <label htmlFor='clientphone' className={styles.reg_form_label}>Телефон</label>
                        <input id='clientphone' type='textbox' className={styles.name}  readOnly='true' defaultValue={'+7' +regisration.phone}/>
                        <label htmlFor='clientemail' className={styles.reg_form_label}>Почта</label>
                        <input id='clientemail' type='email' className={styles.name}  />
                        <label htmlFor='clientbirthday' className={styles.reg_form_label}>Дата рождения</label>
                        <input id='clientbirthday' type='date' className={styles.name}  defaultValue="2023-11-27" /> */}
                        
                        <div  className={regFromCheckBox.checkbox_container} style={{userSelect:'none'}}>
                            <input type='checkbox' id='allowpersonalinfo'></input>
                            <label htmlFor={'allowpersonalinfo'}>Я согласен(на) на обработку персональный данных</label>
                        </div>
                        
                    </form>
                    
                    <button className={styles.button_accept} onClick={beginResitrateNewClient}>Регистрация</button>                 
                </div>
                <PersonalInforamtion></PersonalInforamtion>
                
            </div>
        )
    }



    //Выводим список карт
    if (regStore.clientCards !== null)
    {
        return (
            <div  className={styles.root}>
                { TopMessage()}
                <div className={styles.center}>
                    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                        <h1>Карты на номер </h1>
                        <div style={{display:'flex',flexDirection:'row',alignItems:'center',gap:'5px'}} className={styles.registered_client_phone}> 
                            +7{regStore.clientCards.phone}
                            <img style={{filter: GlobalStore.Theme == 'light' ? 'invert(100%)' : ''}} src='/images/controls/ic_close_1.png' alt='remove' onClick={logoff}/>
                        </div>
                    </div>
                    { regStore.clientCards.wallets !== null && regStore.clientCards.wallets.length > 0 ?
                        <div className={styles.cards_list}>
                            <h2>Выданные виртуальные карты</h2>
                            { regStore.clientCards.wallets.map(wallet => <BonusCardView key={wallet.id} wallet={wallet} card={wallet.client} onClick={() => openCardModal(wallet, wallet.client)} ></BonusCardView>)}
                        </div>
                    : ''}
                    {regStore.clientCards.cards !== null && regStore.clientCards.cards.length > 0 ?
                        <div className={styles.cards_list}>
                            <h2>Доступные для регистрации</h2>
                            {regStore.clientCards.cards.map(card => <BonusCardView key={card.id} wallet={null} card={card} onClick={() => openCardModal(null, card)} ></BonusCardView>)}
                        </div>
                    : ''}
                </div>
                {
                    modalCard == null ? '' :
                    <BonusCardModal card={modalCard.card} wallet={modalCard.wallet} onClose={() => setModalCard(null)} onChanged={()=>regStore.refreshCardsList()}> </BonusCardModal>
                }
                
            </div>
        )
    }

    return (
        regStore.loading === true ? 
            <div></div> 
            :
            <div className={styles.root}>
                { TopMessage()}
                <div className={styles.content_root}>
                    {
                        verifyData !== null ? verifyComponent() :
                        regStore.clientRegistrationStatus === null ? 
                        phoneInputBlock() : phoneEntered()
                    }
                </div>
        </div>
        
    );
})

export default LoyaltyRegistration;