import React from 'react';
import PropTypes from 'prop-types';
import styles from './NavigationItem.module.css'
import { NavLink } from 'react-router-dom';

const NavigationItem = (props) => {

    const {id, separator, expandMode, text, navTo, ...otherProps} = props;

    return (
        <NavLink {...otherProps} to={navTo} className={`${styles.root} ${expandMode ? styles.root_anim : ''}`} style={{animationDelay:`${id * 100 + 200}ms`}}>
            <div className={styles.text} >{text}</div>
            { separator && expandMode === true ? <div className={styles.separator}></div> : ''}
        </NavLink>
        
    );
};

NavigationItem.defaultProps = {
    id : 0,
    expandMode : false,
    navTo : '',
    text : '',
    separator : false
}

NavigationItem.propTypes = {
    id : PropTypes.number,
    separator : PropTypes.bool,
    expandMode : PropTypes.bool,
    navTo : PropTypes.string,
    text : PropTypes.string,
}

export default NavigationItem;