import React, {Component} from 'react';
import styles from './loyalty.module.css'
import { NavLink } from 'react-router-dom';

export class Loyalty extends Component{
    render(){
        return(
            <div className={styles.loyalty_root}> 
                <div className='content'>
                    <div className={styles.title}>Программа лояльности</div>
                    <img className={styles.cardimg} src="/images/gift_card.png" alt='dcf' />
                    {/* <div className={styles.rest_logo_list}>
                        <img className={styles.rest_logo} style={{padding:'8px 0px'}} src='images/varvara/logo_white_128.png'></img>
                        <img className={styles.rest_logo} src='images/izbushka/logo_white_128.png'></img>
                    </div> */}
                    <div className={styles.reg_link_root}>
                        <NavLink className={styles.reg_link} to="/loyalty_registration">Зарегистрировать карту</NavLink>
                    </div>
                    
                    <p>В ресторане <b>Варвара Бар</b> и  кафе <b>Избушка</b> действует программа лояльности для всех наших клиентов.</p>
                    <p>Оформите бонусную карту, копите бонусы и расплачивайтесь ими при посещении нашего заведения.</p>
                    <p>При оплате заказа вы получите <span className={styles.strong_text}>10%</span> на остаток бонусной карты </p>
                    <p >Вы можете потратить до <span className={styles.strong_text}>50%</span> при оплате заказа </p>
                    
                    
                </div>
                
            </div>
        )
    }
}

export default Loyalty