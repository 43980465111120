import React from 'react';
import PropTypes from 'prop-types';
import styles from './BonusCardView.module.css'

const BonusCardView = (props) => {

    if (props.card === null)
    return;

    const card = props.card;

    return (
        <div {...props} className={styles.root}>
            <div className={styles.card}>{card.card}</div>
            <div className={styles.name}>{card.name}</div>
            <div className={styles.balance}>
                <span className={styles.bonuses_title}>{'Баланс:'}</span>
                {
                    card.balanceBonuses === 0 ?
                    <span className={styles.bonuses_zero}>{card.balanceBonuses}</span> :
                    <span className={styles.bonuses}>{card.balanceBonuses}</span>
                }
                
            </div>
        </div>
    );
};

BonusCardView.defaultProps = {
    card : null,
    wallet : null
};

BonusCardView.propTypes = {
    card : PropTypes.object,
    wallet : PropTypes.object
}

export default BonusCardView;