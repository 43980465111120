import React, { useRef, useState } from 'react';
import styles from './DeliveryAddressAdd.module.css'
import { deliveryStore } from '../../stores/DeliveryStore';
import PropTypes from 'prop-types';

const DeliveryAddressAdd = (props) => {
    const { onCloseClick, onAddClick, ...otherProps } = props;

    const [addressResults, setAddressResult] = useState(null);
    const [showAddressDropDown, setShowAddressDropDown] = useState(false);
    const [selectedAddressResult, setSelectedAddressResult] = useState(null);
    
    const searchAddressRef = useRef();

    const cityRef = useRef();
    const streetRef = useRef();
    const houseRef = useRef();
    const flatRef = useRef();
    const entranceRef = useRef();
    const floorRef = useRef();
    const intercomRef = useRef();

    const contentRef = useRef();
    const rootRef = useRef();

    var timerRef = useRef();

    const isNullOrEmpty = (text) =>{
        return (text == null || text.length == 0)
    }

    const pushAddressToServer = async ()=>{
        var address = {
            city: {
                name: cityRef.current.value,
            },
            street: {
                name: streetRef.current.value,
            },
            houseNumber: houseRef.current.value,
            flatNumber: flatRef.current.value,
            floor: floorRef.current.value,
            entrance: entranceRef.current.value,
            intercom: intercomRef.current.value,
        }
        if (isNullOrEmpty(address.city.name) || isNullOrEmpty(address.street.name) || isNullOrEmpty(address.houseNumber))
            console.log("ЗАПОЛНИТЕ!")
        else{
            console.log(address);
            console.log(await deliveryStore.addAddress(address));
            onCloseClick();
            
        }
            
    }

    const close = (callback)=>{
        try{
            console.log(styles.hide_content2);
            console.log(styles.hide_root3);
            contentRef.current.style.animation = `${styles.hide_content2} 0.3s both`;
            // rootRef.current.style.animation = `${styles.hide_root3} 0.3s both`;
            setTimeout(()=>{
                onCloseClick();
                if (callback != null)
                    callback();
            },300)
            
        }
        catch
        {

        }
        
    }



    const debounce = (mainFunction, delay) => {
        return function (...args) {
          clearTimeout(timerRef.current);
          timerRef.current = setTimeout(() => {
            mainFunction(...args);
          }, delay);
        };
    };

    const searchTextChanged = (e) =>{
        const text = e.target.value;
        const text_empty = text != null && text.length <= 7;
        const debounce1 = debounce(() => PrintResults(text), text_empty ? 0 : 1500);
        debounce1();
    }

    const PrintResults = async(text) =>{
        if (text != null && text.length > 7){
            var addresses = await deliveryStore.suggestAddresses(text);
            setAddressResult(addresses);
            setShowAddressDropDown(true);
        } else{
            setAddressResult(null)
            setShowAddressDropDown(false);
        }
    }

    const selectAddress = (address) =>{
        setSelectedAddressResult(address);
        setShowAddressDropDown(false);
        try{
            if (address != null){
                
                if (address.data.city != null){
                    cityRef.current.value = address.data.city;
                } else{
                    cityRef.current.value = address.data.settlement_type + " " + address.data.settlement;
                }
                if (address.data.street != null){
                    streetRef.current.value = address.data.street_with_type;
                }
                const house = address.data.house + "" +
                    (address.data.block_type != null ? address.data.block_type.toString() : '') +
                    (address.data.block != null ? address.data.block.toString() : '');
                houseRef.current.value = house;
                if (address.data.flat != null){
                    flatRef.current.value = address.data.flat;
                }
            }
        }catch{

        }
        searchAddressRef.current.value = address.value;
    }

    const AddressDropDown = () =>{
        if (showAddressDropDown === false)
            return;
        else
        return(
            <div className={styles.result_dropdown_root} >
                { addressResults.map((address) => 
                    <div key={address.value} 
                         className={styles.result_dropdown_address} 
                         onClick={()=> selectAddress(address)}
                         >{address.value}</div> 
                ) }
            </div>
        )
    }

    return (
        <div {...otherProps} className={styles.root} ref={rootRef}> 
            <div className={styles.content} ref={contentRef}>
                
                <h1>Добавить новый адрес</h1>
                <div className={styles.address_input_root}>
                        <input ref={searchAddressRef} type='text' autoFocus className={styles.input_address} placeholder='Быстрый ввод адреса' 
                            onChange={(e) => searchTextChanged(e)} 
                            ></input>
                        { addressResults == null ? '' : AddressDropDown() }
                    </div>
                <form className={styles.address_form}>
                    
                    <div className={styles.input_city}>
                        <input ref={cityRef} type='text' id='city-input' required></input>
                        <label htmlFor='city-input'>Населенный пункт</label>
                    </div>
                    <div className={styles.input_street}>
                        <input ref={streetRef} type='text' id='street-input' required></input>
                        <label htmlFor='street-input'>Улица</label>
                    </div>
                    <div className={styles.input_house}>
                        <input ref={houseRef} type='text' id='house-input' required></input>
                        <label htmlFor='house-input'>Дом</label>
                    </div>
                    <div className={styles.input_flat}>
                        <input ref={flatRef} type='text' id='flat-input' required></input>    
                        <label htmlFor='flat-input'>Квартира</label>
                    </div>
                    <div className={styles.input_entrance}>
                        <input ref={entranceRef} type='text' id='entrance-input' required></input>    
                        <label htmlFor='entrance-input'>Подъезд</label>
                    </div>
                    <div className={styles.input_floor}>
                        <input ref={floorRef} type='text' id='floor-input' required></input>    
                        <label htmlFor='floor-input'>Этаж</label>
                    </div>
                    <div className={styles.input_intercom} >
                        <input ref={intercomRef} type='text' id='intercom-input' required></input>    
                        <label htmlFor='intercom-input'>Домофон</label>
                    </div>
                </form>
                <div className={styles.bottom_panel}>
                    <button className={styles.button_accept} onClick={()=> pushAddressToServer()}>ДОБАВИТЬ</button>
                    <button className={styles.button_close} onClick={ () => close() } >ОТМЕНА</button>
                </div>
            </div>
        </div>
    );
};

DeliveryAddressAdd.defaultProps = {
    onAddClick : () => {},
    onCloseClick : () => {}
}

DeliveryAddressAdd.propTypes = {
    onAddClick : PropTypes.func,
    onCloseClick : PropTypes.func
}


export default DeliveryAddressAdd;