export const preload = src => new Promise((resolve, reject) => { 
  try
  {
    const img = new Image()
    img.src = src
    img.onload = resolve
    img.onerror = reject
  }
  catch {}
})

export const preloadAll = srcs =>
{
  try
  {
    return Promise.all(srcs.map(preload));
  }
  catch {}
} 
