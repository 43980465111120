import React, { useEffect, useState } from 'react';
import styles from './DeliveryCart.module.scss'
import productStyles from './DeliveryCart.Product.module.css'
import DeliveryAddressess, { DeliveryAddress } from './DeliveryAddressess';
import { deliveryStore } from '../../stores/DeliveryStore';
import { observer } from 'mobx-react-lite';
import GlobalStore from '../../stores/GlobalStore';

const DeliveryCart = observer(() => { 
    const [selectAddressModal, setSelectAddressModal] = useState(false);
    


    useEffect(()=>
    {
        const init = async () =>{
            await getData();
        }
        init();
        return(()=>
            {
                console.log('delivery_unmount')
            })
    },[])

    const getData = async () =>{
        await deliveryStore.FetchMenu();
        await deliveryStore.GetCart();
        deliveryStore.StopLoading();
    }

    if (deliveryStore.isLoading === true){
        return (
            <div className={styles.loading_message}> Загрузка...</div>
        )
    }

    const addressSelectedEvent = (address)=>{
        if (address != null){
            deliveryStore.SelectedAddress = address;
        }
        
    }

    const expeditonTypeChanged = (e) =>{
        if (e.currentTarget.id == 'et-delivery') {
            deliveryStore.DeliveryInformation.expeditionType = 'delivery';
        } else{
            deliveryStore.DeliveryInformation.expeditionType = 'pickup';
        }
    }

    const informationBlock = () =>{
        return(
            <div className={styles.client_info}>
                <h2>Контактная информация</h2>
                <form className={styles.client_info_form}>
                    <div className={styles.input_simple}>
                        <input type='name' id='client-name-input' placeholder='Имя' required></input>
                        <label htmlFor='client-name-input'>Имя</label>
                    </div>
                    <div className={styles.input_phone}>
                        
                        <input  type='phone' id='client-phone-input' required
                            autoComplete="off" inputMode="numeric"  maxLength={10} placeholder='Телефон'></input>
                            
                        <label htmlFor='client-phone-input'>Телефон</label>
                        <p>+7</p>
                    </div>
                    <div className={styles.input_simple}>
                        <input type='email' id='client-email-input' placeholder='Электронная почта' required></input>
                        <label htmlFor='client-email-input'>Электронная почта</label>
                    </div>
                    
                </form>
                <div className={styles.expedition_type_panel}>
                    <div className={styles.et_button}>
                        <input type='radio' id='et-delivery' name='expedition-type' defaultChecked onChange={(e)=>expeditonTypeChanged(e)}/>
                        <label htmlFor='et-delivery' >Доставка</label>
                    </div>
                    <div className={styles.et_button}>
                        <input type='radio' id='et-pickup' name='expedition-type' onChange={(e)=>expeditonTypeChanged(e)}/>
                        <label htmlFor='et-pickup' >Самовывоз</label>
                    </div>
                </div>
                { deliveryStore.DeliveryInformation.expeditionType !== 'delivery' ? '' :
                    <div className={styles.delivery_address}>
                    {
                        deliveryStore.SelectedAddress == null ? 
                        <button className={styles.button_address} onClick={()=> setSelectAddressModal(true)} >
                            <img src={GlobalStore.Theme == 'dark' ? '/images/controls/map_address_icon.png' : '/images/controls/map_address_dark_icon.png'}></img>
                            <p>Выбрать адрес</p>
                        </button>
                        :
                        <button className={styles.button_address_filled} onClick={()=> setSelectAddressModal(true)} >
                            <img src={GlobalStore.Theme == 'dark' ? '/images/controls/map_address_dark_icon.png' : '/images/controls/map_address_icon.png'}></img>
                            <p>{deliveryStore.getAddressString(deliveryStore.SelectedAddress)}</p>
                        </button>
                    }
                    </div>                
                }
                
                {/* 3 block */}
                <div className={styles.payment_panel}>
                    <h2>Способ оплаты</h2>
                    <div className={styles.payment_selector}>
                        <div className={styles.pt_button}>
                            <input type='radio' id='et-delivery' name='payment-type' defaultChecked />
                            <label htmlFor='et-delivery' >Наличными</label>
                        </div>
                        <div className={styles.pt_button}>
                            <input type='radio' id='et-pickup' name='payment-type' />
                            <label htmlFor='et-pickup' >Картой </label>
                        </div>
                    </div>
                </div>
                
            </div>
            
        )
    }


    return (
        <div className={styles.root}>
                { !selectAddressModal ? '' :
                    <DeliveryAddressess onSelectedClick={(e) => addressSelectedEvent(e)} 
                        mode='select' 
                        selectedAddressId={deliveryStore.SelectedAddress != null ? deliveryStore.SelectedAddress.id : null}
                        onCloseClick={()=>setSelectAddressModal(false)}></DeliveryAddressess>
                }
                <div className={styles.head}>
                    <h1>Ваш заказ {deliveryStore.Cart.sum} руб.</h1>
                </div>
                
                <div className={styles.main_panel}>
                    {/* 1 block */}
                    <div className={styles.products_list}>
                        <h2>Состав заказа</h2>
                        {
                            deliveryStore.Cart == null || deliveryStore.Cart.products == null ? '' : 
                            deliveryStore.Cart.products.map(cart_product => {
                                return (
                                <DeliveryCartProduct key={cart_product.id}
                                    product={deliveryStore.products.find(x=> x.id === cart_product.product_id)}
                                    cart_product={cart_product}/>
                                )
                            })
                        }
                    </div>
                    {/* 2 block */}
                    { informationBlock()}
                    
                </div>
                
                
            </div>
    );
});

export default DeliveryCart;



const DeliveryCartProduct = (props) =>{

    
    const {product, cart_product, ...otherProps} = props;
    
    if (product == null)
        return ;

    const imageBlock = () => {
        if (product.imageUrl == null){
            return (
                <img src="/images/photo_placeholder.png" alt='ph'></img>
            )
        } else{
            return(
                <img src={'/api/img/thmb/'+ product.imageUrl} alt={product.imageUrl} onError={({ currentTarget }) => {
                    currentTarget.onerror = null; 
                    currentTarget.src="/images/photo_placeholder.png";
                }}></img>
            )
        }
    }

    const cartMinusClick = (e, cart_product) =>{
        e.stopPropagation();
        deliveryStore.minusCart(cart_product.id)
    }

    const cartPlusClick = (e, cart_product) =>{
        e.stopPropagation();
        deliveryStore.plusCart(cart_product.id)
    }

    const ingredients = ()=>{
        if (cart_product.ingredients != null && cart_product.ingredients.length > 0)
        {
            const ingredients = cart_product.ingredients;
            return (
                <div className={productStyles.ingredients_list}>
                    {
                        ingredients.map((x,i) => 
                        {
                            const ingredient = deliveryStore.GetIngredient(product.id, x.ingredient_id)
                            if (ingredient != null){
                                return(
                                    <p key={x.id}  style={{fontSize:'13px', fontWeight:'400', opacity:'0.7'}} className={styles.ingredient}>
                                        {`${x.quantity}    x   ${ingredient.name}`}
                                    </p> 
                                )
                            } else{
                                return(
                                    <p key={x.id} className={productStyles.ingredient} style={{fontSize:'13px', fontWeight:'400', opacity:'0.7'}}>{'Ингредиент не найден'}</p>
                                )
                            }
                        })
                    }
                </div>
            )
        }
    }

    return (
        <div {...otherProps} className={productStyles.product_root}>
            { imageBlock()}
            <div className={productStyles.cart_product_info}>
                <div className={productStyles.cart_product_name}>
                    <p>{product.name}</p>
                    { ingredients() }
                    
                </div>
                <div className={productStyles.cart_product_quantity_and_price}>
                    {
                        cart_product.price_full != null && cart_product.price_full > 0 ? 
                        <div className={productStyles.cart_product_price}>
                            {cart_product.price_full} 
                            <span style={{fontSize:'0.8em', fontWeight:'400', opacity:'0.7'}}> ₽</span>
                        </div> 
                        : ''
                    }
                    <div className={productStyles.cart_product_buttons}> 
                        <button onClick={(e) => cartMinusClick(e, cart_product)}>-</button>
                        <p onClick={(e) => {e.stopPropagation()}}>{cart_product.quantity}</p>
                        <button onClick={(e) => cartPlusClick(e, cart_product)}>+</button>
                    </div>
                    {/* {
                        cart_product.sum != null && cart_product.sum > 0 ? 
                        <div className={productStyles.cart_product_sum}>{cart_product.sum} ₽</div> 
                        : ''
                    } */}
                </div>
            </div>
            
            
        </div>
    )
}
