import React, { useEffect } from "react";
import appStyles from './App.module.css';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import Home from './components/home';
import { Users }  from './users';
import  LoyaltyRegistration  from './components/loyalty/LoyaltyRegistration';
import  Loyalty from './components/loyalty/loyalty'
import CookieMessage from "./components/CookieMessage";
import NavigationMenu from "./components/navigation/NavigationMenu";
import Delivery from "./components/DeliveryDashboard";
import PageNotFound from "./components/PageNotFound";
import GlobalStore from "./stores/GlobalStore";
import DeliveryCart from "./components/delivery/DeliveryCart";

export default function App() {

  useEffect(()=>{
    const init = async()=>{
      GlobalStore.getAuthInfo();
    };
    init();

  },[])

 




  return (
    <Router>
      <div className={appStyles.App}>
          <NavigationMenu>
            
          </NavigationMenu>
            
          <Routes>
            <Route path="/" element={<Home/>}/>
            <Route path="/home" element={<Home/>}/>
            <Route path="/users" element={<Users/>}/>
            <Route path="/loyalty" element={<Loyalty/>}/>
            <Route path="/loyalty_registration" element={<LoyaltyRegistration/>}/>
            <Route path="/delivery" element={<Delivery/>}/>
            <Route path="/delivery/cart" element={<DeliveryCart/>}/>
            <Route path="*" status={404} element={<PageNotFound/> }/>
          </Routes>
          <footer className={appStyles.footer}>
            
            
            <ul className={appStyles.menu}>
              <li className={appStyles.menu__item}><HashLink className={appStyles.menu__link} to="/#root">О ресторане</HashLink></li>
              <li className={appStyles.menu__item}><HashLink className={appStyles.menu__link} to="/">Контакты</HashLink></li>
              <li className={appStyles.menu__item}><HashLink className={appStyles.menu__link} to="/">Отзыв</HashLink></li>
            </ul>

            <ul className={appStyles.socialmedia}>
              {/* <img className="wowlogo" src={"/images/restwow_logo_lightgray.png"} alt='wowlogo'/> */}
              <a href='https://vk.com/varvara_bar'>
                <img className={`${appStyles.cosialmediaicon} ${appStyles.menu__link}`} src="/images/social_media_vk.png" alt='vk'/>
              </a>
            </ul>

            <div className={appStyles.copyright}>
              <p>Garagulya Prokhor © 2023</p>
            </div>
          </footer>
          <CookieMessage></CookieMessage>
          </div>
          
      </Router>
      
  );
}