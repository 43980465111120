import {  makeAutoObservable, runInAction } from "mobx";

class LoyaltyRegistrationStore
{
    loading = true;
    clientCards = null;
    clientRegistrationStatus = null;
    clientPhone = null;
    isPhoneCorrect = false;
    clientFormData = null;
    requestMessage = null;

    constructor(){
        makeAutoObservable(this)
    }

    stopLoading(){
        runInAction(()=>{
            this.loading = false;
        })
    }

    setIsPhoneCorrect(value){
        this.isPhoneCorrect = value;
    }

    setClientRegistrationStatus(value){
        this.clientRegistrationStatus = value;
    }

    async refreshCardsList(){
        try{
            const resp = await fetch(`api/client/wallet`);
            if (resp.status === 200){
                const result = await resp.json();
                if (result != null){
                    runInAction(()=>{
                        this.clientCards = result;
                    })
                }
            } 
            else{
                return null;
            }
        }
        catch(error){
            console.log(error);
            return null;
        }
    }

    async verifyPhoneByCode(phone, code){
        try{
            const resp = await fetch(`api/client/auth/verify?phone=${phone}&code=${code}`, { method:'POST' });
            if (resp.status === 200){
                return { result: true, message: 'ok'}
            } 
            else {
                const result = await resp.json();
                return { result: false, message: result.message}
            }
        }
        catch(error){
            console.error(error);
            return { result: false, message: 'error'}
        }
    }

    async checkUserRegestration(){
        const resp = await fetch(`api/client/check_processing?phone=${this.clientPhone}`);
        if (resp.status === 200){
            const reg_status = await resp.text();
            runInAction(()=>{
                this.clientRegistrationStatus = reg_status;    
            })
            console.log(reg_status);
        } else{
            
        }
    }

    checkPhoneCorrect(phone){
        if (phone === null)
            return false;
        if (phone.length !== 10)
            return false;
        const isDigits = /^\d+$/.test(phone);
        if (!isDigits || Array.from(phone)[0] !== '9')
            return false;
        return true;
    }

    showMessage(text){
        this.requestMessage = text;
        setTimeout(()=>{
            this.requestMessage = null;
        }, 3000)
    }
}

export default new LoyaltyRegistrationStore()