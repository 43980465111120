import React, { useState } from 'react';
import styles from './PhoneVerification.module.css'
import { observer } from 'mobx-react-lite';
import GlobalStore from '../stores/GlobalStore';




const PhoneVerification = observer((props) => {

    const [codeValue, setCodeValue] = useState([...Array(4)].map((x)=>''));
    const [isCodeValid, setIsCodeValid] = useState(false);

    const  onChange = async (index, e)=>{
        const text = e.target.value;
        const id = e.target.id;
        switch(id){
            case 'VC1': 
                if (text.length > 0){
                    const next1 = document.getElementById('VC2');
                    next1.focus();
                } 
                break;
            case 'VC2': 
                if (text.length > 0){
                    const next2 = document.getElementById('VC3');
                    next2.focus();
                } 
                break;
            case 'VC3': 
                if (text.length > 0){
                    const next3 = document.getElementById('VC4');
                    next3.focus();
                } 
                break;
            case 'VC4': 
                if (text.length > 0){
                    const accept_button = document.getElementById('codeaccept');
                    accept_button.focus();
                } 
                break;
            default:
                return;
        }
        const new_code = [...codeValue]
        new_code[index] = text;
        setCodeValue(new_code);
        const v = validateCode(new_code);
        if (v !== isCodeValid)
            setIsCodeValid(v);
    }


    const validateCode = (code) =>{
        const text = code.join('')
        if (text == null || text.length < 4)
            return false;
        if (text.match(/^-?\d+$/) === false)
            return false;
        return true;
    }

    const onKeyDown = (index, e)=>{
        
        var key = e.keyCode;
            if (key >= 48 && key <= 57) {
                e.target.value = '';
            }
        const id = e.target.id;
        if (e.key === 'Backspace' || e.key === 'Delete'){
            const new_code = [...codeValue]
            new_code[index] = '';
            setCodeValue(new_code);
            const v = validateCode(new_code);
            if (v !== isCodeValid)
                setIsCodeValid(v);
            e.preventDefault();
            switch(id){
                case 'VC1': 
                    
                    break;
                case 'VC2': 
                        const prev2 = document.getElementById('VC1');
                        prev2.focus();
                    break;
                case 'VC3':
                        const prev3 = document.getElementById('VC2');
                        prev3.focus();
                    break;
                case 'VC4': 
                        const prev4 = document.getElementById('VC3');
                        prev4.focus();
                    break;
                default:
                    return;
            }
        } else{
            
        }
    }

    const onPaste = (e)=>{
        //e.preventDefault();
        var text = e.clipboardData.getData('text')
        text = text.replace(/[^0-9]/g, '')
        if (text === null)
            return;
        const digits = text.split('');
        
        if (digits === null || digits.length === 0)
            return;
        
        var values = [];
        switch(digits.length){
            case 1:
                values = [ digits[0] ,'','','' ]
                break;
            case 2:
                values = [ digits[0], digits[1],'','' ]
                break;
            case 3:
                values = [ digits[0], digits[1], digits[2], '' ]
                break;
            case 4:
                values = [ digits[0], digits[1], digits[2], digits[3] ]
                break;
            default:
                break;
        }
        setCodeValue(values);
        const accept_button = document.getElementById('codeaccept');
        accept_button.focus();
        if (digits.length === 0)
            return;
    }

    const onAcceptClick = (e) =>{
        const code = codeValue.join('');
        if (code.length !== 4)
            return;
        props.onAccept(code);
    }

    const onCloseClick = (e) =>{
        props.onClose();
    }

    return (
        <div className={styles.root}>
            <div className={styles.top}>
                <button className={styles.back_button } style={{filter: GlobalStore.Theme == 'light' ? 'invert(100%)' : ''}} onClick={onCloseClick} >
                    <img src='images/controls/ic_arrow_back.png' alt='back'></img>
                </button>
            </div>
            <div className={styles.center}> 
                <div className={styles.verification_code_text}>
                    <p>Отправили Вам SMS</p>
                    <p>Введите код подтверждения из сообщения</p>
                </div>
                <div className={styles.verification_code_inputs} >
                {
                    [...Array(4)].map((v, i) => (
                        <input className={styles.code_number} key={i + 1} id={`VC${i + 1}`} value={codeValue[i]} 
                            onChange={(e)=>onChange(i, e)} onKeyDown={(e)=> onKeyDown(i, e)} onPaste={(e)=> onPaste(e)} 
                            maxLength={1} autoComplete="off" type='text' inputMode="numeric" placeholder='0'
                            autoFocus={i === 0 ? true : ''}/>
                    ))}
                </div>
            </div>
            <div  className={styles.bottom}>
                <button id='codeaccept' disabled={!isCodeValid} className={styles.button_accept} onClick={onAcceptClick}>Подтвердить</button>
            </div>
        </div>
    )
});

PhoneVerification.defaultProps = {
    onAccept: ()=>{},
    onClose: ()=>{}
}

export default PhoneVerification;