import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import styles from './DeliveryProductListItem.module.css'
import { deliveryStore } from '../../stores/DeliveryStore';

const DeliveryProductListItem = (props) => {

    const {product, onAddClick, onBodyClick, onMinusClick, onPlusClick, ...otherProps} = props;

    const imgRef = useRef();

    if (product == null){
        return;
    }

    const cartButtonClick = (e) =>{
        e.stopPropagation();
        const img = imgRef.current;
        const rect = img.getClientRects()[0];
        onAddClick(product, rect);
    }

    const cartMinusClick = (e) =>{
        e.stopPropagation();
        onMinusClick(product);
    }

    const cartPlusClick = (e) =>{
        e.stopPropagation();
        onPlusClick(product);
    }

    const bodyClicked = (e) => {
        const img = imgRef.current;
        const rect = img.getClientRects()[0];
        onBodyClick(product, rect);
    }


    const imageBlock = () => {
        if (product.imageUrl == null){
            return (
                <img src="/images/photo_placeholder.png" alt='ph'></img>
            )
        } else{
            return(
                <img src={'/api/img/thmb/'+ product.imageUrl} alt={product.imageUrl} onError={({ currentTarget }) => {
                    currentTarget.onerror = null; 
                    currentTarget.src="/images/photo_placeholder.png";
                }}></img>
            )
        }
    }

    ///Кнопка корзина
    const CartButton = () =>{
        const product_in_cart = deliveryStore.GetProductInCart(product.id);
        if (product.ingredients == null && product.ingredientsGroups == null){
            
            if (product_in_cart == null){
                return(
                    <button className={styles.cart_button_add} onClick={(e)=> cartButtonClick(e)}>Добавить</button>    
                )
            } else{
                return(
                    <div className={styles.cart_buttons}> 
                        <button onClick={(e) => cartMinusClick(e)}>-</button>
                        <p onClick={(e) => {e.stopPropagation()}}>{product_in_cart.quantity}</p>
                        <button onClick={(e) => cartPlusClick(e)}>+</button>
                    </div>
                )
            }
        } else{
            if (product_in_cart == null){
                return(
                    <button className={`${styles.cart_button_add}`} onClick={(e)=> cartButtonClick(e)}>Добавить</button>    
                )
            }
            return(
                <button className={`${styles.cart_button_add} ${styles.cart_button_add_in_cart}`} onClick={(e)=> cartButtonClick(e)}>Добавить</button>    
            )
        }
    }

    return (
        <div className={styles.root} {...otherProps} onClick={(e) => bodyClicked(e)}>
            <div ref={imgRef} className={styles.image_root}>
                { imageBlock() }
            </div>
            <div className={styles.info}>
                <div className={styles.name} >{product.name}</div>
                <div className={styles.properties}>
                    <div className={styles.weight}>{product.weight === null || product.weight === '' ? "" : (product.weight + 'гр')}</div>
                    <div className={styles.price}>{product.price + '₽'}</div>
                </div>
                <div className={styles.bottom}>
                    { CartButton() }
                </div>
            </div>
        </div>
    );
};

DeliveryProductListItem.defaultProps = {
    product : null,
    onAddClick : () => {},
    onBodyClick : () => {},
    onPlusClick : () => {},
    onMinusClick : () => {},
}

DeliveryProductListItem.propTypes = {
    product : PropTypes.object,
    onBodyClick : PropTypes.func,
    onAddClick : PropTypes.func,
    onPlusClick : PropTypes.func,
    onMinusClick : PropTypes.func,
}

export default DeliveryProductListItem;