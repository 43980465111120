import React, {Component} from 'react';
import styles from './personal_inforamtion.module.css'


export class PersonalInforamtion extends Component{
    render(){
        return(
            <div {...this.props} className={styles.per_inf}> 
                <p>Предоставляя свои персональные данные Пользователь даёт согласие на обработку, хранение и использование своих персональных данных на основании ФЗ № 152-ФЗ «О персональных данных» от 27.07.2006 г. в следующих целях:</p>

                <p>Регистрации Пользователя на сайте</p>
                <ol>
                    <li>Осуществление клиентской поддержки</li>
                    <li>Получения Пользователем информации о маркетинговых событиях</li>
                    <li>Выполнение Ресторана обязательств перед Пользователем</li>
                    <li>Проведения аудита и прочих внутренних исследований с целью повышения качества предоставляемых услуг.</li>
                </ol>
                <p>Под персональными данными подразумевается любая информация личного характера, позволяющая установить личность Покупателя такая как:</p>
                <ol>
                    <li>Фамилия, Имя, Отчество</li>
                    <li>Дата рождения</li>
                    <li>Контактный телефон</li>
                    <li>Адрес электронной почты</li>
                </ol>
                <p>Персональные данные Пользователей хранятся исключительно на электронных носителях и обрабатываются с использованием автоматизированных систем, за исключением случаев, когда неавтоматизированная обработка персональных данных необходима в связи с исполнением требований законодательства.</p>
                <p>Ресторан обязуется не передавать полученные персональные данные третьим лицам, за исключением следующих случаев:</p>
                <ol>
                    <li>По запросам уполномоченных органов государственной власти РФ только по основаниям и в порядке, установленным законодательством РФ </li>
                    <li>Стратегическим партнерам, которые работают с Рестораном для предоставления продуктов и услуг, или тем из них, которые помогают Ресторану реализовывать продукты и услуги потребителям. Мы предоставляем третьим лицам минимальный объем персональных данных, необходимый только для оказания требуемой услуги или проведения необходимой транзакции.</li>
                </ol>
                <p>Ресторан оставляет за собой право вносить изменения в одностороннем порядке в настоящие правила, при условии, что изменения не противоречат действующему законодательству РФ. Изменения условий настоящих правил вступают в силу после их публикации на Сайте.</p>
            </div>
        )
    }
}

export default PersonalInforamtion