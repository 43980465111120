import {  makeAutoObservable, runInAction } from "mobx";

class GlobalStore
{
    AuthInfo = null;
    Theme = '';

    constructor(){
        makeAutoObservable(this)
    }

    ///ПОЛУЧИТЬ ИНФО ПО АВТОРИЗАЦИИ
    async getAuthInfo(){
        const resp = await fetch('/api/auth');
        if (resp.status === 200){
            const info = await resp.json();
            runInAction(()=>{
                // console.log(info);
                this.authInfo = info;
            })
        }
    }

    enableOverflow = () =>{
        document.body.style.overflow = 'auto';
    }

    disableOverflow = () =>{
        document.body.style.overflow = 'hidden';
    }
}



export default new GlobalStore()