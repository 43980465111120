import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './BonusCardModal.module.css'
import BonusCardView from './BonusCardView';
import { getMobileOsType } from '../../helper';
import { useEffect } from 'react';
import {BeatLoader} from 'react-spinners'
import { createRef } from 'react';

const BonusCardModal = (props) => {

    const device = getMobileOsType();
    const[card, setCard] = useState(props.card);
    const[wallet, setWallet] = useState(props.wallet);
    const[googlePlayLoading, setGooglePlayLoading] = useState(false);

    const rootRef = createRef();
    const contentRef = createRef();
    
    useEffect(()=>{
        document.body.style.overflow = 'hidden';
        return(()=>{
            document.body.style.overflow = 'auto';
        })
    },[])

    const registrateCard = async()=>{
        const resp = await fetch(`/api/client/wallet/${card.id}`, {method:'PUT'});
        if (resp.status === 200){
            const o = await resp.json();
            if (o !== null){
                props.onChanged();
                setWallet(o);
                setCard(o.client)
            }
            console.log(o);
        }else{
            console.log(resp.status);
        }
    }

    const appleClicked = async ()=>{
        try{
            
            const response = await fetch(`/api/client/wallet/apple/${wallet.id}`);

            console.log(response.url);
            if (response.ok){
                const blob = await response.blob();
                const file = window.URL.createObjectURL(blob);
                window.location.assign(file);
            }
        }catch (error){
            console.error(error);
        }
    }

    const googleClicked = async ()=>{
        //console.log(wallet.googleWallet.JWT != null)
        if (wallet.googleWallet != null && wallet.googleWallet.JWT != null){
             window.location.href = wallet.googleWallet.JWT;
         } else{
            try{
                setGooglePlayLoading(true);
                const response = await fetch(`/api/client/wallet/gpay/${wallet.id}`, {method:'POST'});
                if (response.status === 200){
                    const w = await response.json();
                    console.log('JWT Regestrated');
                    if (w != null){
                        console.log('Got wallet', w);
                        if (w.googleWallet != null && w.googleWallet.JWT != null){
                            console.log('Go to JWT URL');
                            window.location.href = w.googleWallet.JWT;
                        }
                        setWallet(w);
                    }
                } else{
                    //const error = await response.text();
                    console.error(response.status);
                }
                setGooglePlayLoading(false);
            }catch (error){
                console.error(error);
            }
         }
        
    }

    const googlePlayButton = ()=>{
        if (googlePlayLoading){
            return(
                <div className={styles.loading_google_pay}>
                    <p>Создание карты</p>
                    <BeatLoader 
                        color='#FFF' 
                        speedMultiplier={1.5}
                        loading={true} size={10} aria-label={'loading'} />
                </div>
            )
        } 
        else{
            return(
                <img className={styles.button_google_pay} src='/images/save_to_google_pay_light.png' alt='googlepay' onClick={googleClicked}></img>
                )}
    }



    const walletButtons = ()=>{
        switch(device){
            case 'iOS':{
                return(
                    <div className={styles.list}>
                        <h2 style={{textAlign:'center', fontSize:'16px'}}>Ваша карта готова</h2>
                        <img className={styles.button_apple_wallet} src='/images/add-to-apple-wallet-logo.png' alt='applewallet' onClick={appleClicked}></img>    
                    </div>
                )}
            case 'Android':{
                return(
                    <div className={styles.list}>
                        <h2 style={{textAlign:'center', fontSize:'16px'}}>Ваша карта готова</h2>
                        {googlePlayButton()}
                    </div>
                )}
            default:{
                return(
                    <div className={styles.list}>
                        <h2 style={{textAlign:'center', fontSize:'16px'}}>Ваша карта готова</h2>
                        <img className={styles.button_apple_wallet} src='/images/add-to-apple-wallet-logo.png' alt='applewallet' onClick={appleClicked}></img>    
                        {googlePlayButton()}
                    </div>
                )}
            }
    }

    const regButtons = ()=>{
        return (
            <div className={styles.list}>
                <h2 style={{textAlign:'center', fontSize:'16px'}}>Физическая карта/из приложения доставки</h2>
                <button className={styles.button_registrate} style={{margin:'20px'}} onClick={registrateCard}>Регистрация</button>
                <h2>Доступно для смартфонов Apple и Android(*)</h2>
                <h2>* Если в телефоне нет GooglePay и сервисов Google, добавить карту не получится. К примеру, большинство Huawei/Honor</h2>
                <h2>Физическая карта после регистрации будет недоступна</h2>
                <h2>Баланс карты не изменится</h2>
                <h2>При замене номера телефона, восстановить доступ к карте не получится</h2>
            </div>
        )
    }

    const onBackClick = (e) =>{
        rootRef.current.style.animation=styles.bg_hide + ' 0.3s ease-out';
        contentRef.current.style.animation=styles.hide + ' 0.3s ease-out';
        setTimeout(() => {
            props.onClose();
        }, 300);
        
    }

    return (
        <div ref={rootRef} className={styles.root} onClick={onBackClick}>
            
            <div ref={contentRef} className={styles.content} onClick={(e)=>e.stopPropagation()}>
                    <button className={styles.back_button} onClick={(e)=>onBackClick(e)}  >
                        <img src='images/controls/ic_arrow_back.png' alt='back'></img>
                    </button>
                <div className={styles.card_root}>
                    <BonusCardView disabled={true} wallet={wallet} card={card}></BonusCardView>
                </div>
                {
                    wallet !== null ?
                    walletButtons() :
                    regButtons()
                }
            </div>
        </div>
    );
};

BonusCardModal.defaultProps = {
    card : null,
    wallet : null,
    onChanged : ()=>{},
    onClose : ()=>{}
};

BonusCardModal.propTypes = {
    card : PropTypes.object,
    wallet : PropTypes.object,
    onChanged : PropTypes.func,
    onClose : PropTypes.func
}

export default BonusCardModal;