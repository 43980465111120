import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './DeliveryProduct.module.css'
import GlobalStore from '../../stores/GlobalStore';
import { preload } from '../../helpers/imagesPreloader';
import { deliveryStore } from '../../stores/DeliveryStore';

const DeliveryProduct = (props) => {
    const {onClose, rect, product, onAddClick, onMinusClick, onPlusClick, ...otherProps} = props;
    
    const [loaded, setLoaded] = useState(false);
    const [ingredientSelection, setIngredientSelection] = useState(false);
    const [selectedIngredients, setSelectedIngredients] = useState([]);
    
    const preloadImage = async ()=>{
        try{
            if (product == null || product.imageUrl == null || product.imageUrl.length === 0)
                return;
            await preload('/api/img/' + product.imageUrl);    
            setLoaded(true);
        }
        catch
        {
            console.error();
        }
    }

    useEffect(()=>{
        GlobalStore.disableOverflow();
        const init = async()=>{
            preloadImage();
            AnimateIn();
        }
        init();
        /// Фейковый роут для нажатия Назад
        window.history.pushState('product-dialog-route', document.title, window.location.href);
        window.addEventListener('popstate', Close);
        ///
        return (()=>{
            /// Фейковый роут для нажатия Назад
            if (window.history.state === 'product-dialog-route') { window.history.back(); }
            window.removeEventListener('popstate', Close);
            ///
            GlobalStore.enableOverflow();
        })
    },[]);

    const contentRef = useRef();
    const uiControlsRef = useRef();
    const rootRef = useRef();


    const Close = () => {
        AnimateOut()
        setTimeout(()=>{
            onClose();
        }, animDuration)
    }

    const animDuration = 300;

    const AnimateIn = () =>{
        const contentElement = contentRef.current;
        const width = window.innerWidth;
        const height = window.innerHeight;
        const left = (width - 700) / 2;
        const top = (height - 500) / 2;
        setTimeout(()=>{
            contentElement.classList.add(styles.content_normal)
        }, animDuration)
        // contentElement.addEventListener("animationend", ()=> 
        // {
        //     console.log("animEnd")
        //     contentElement.classList.add(styles.content_normal)
            
        // });
        if (width < 741){
            const keyFrames = [
                { width: `${rect.width}px`,  height: `${rect.height}px`, left: `${rect.x}px`, top: `${rect.y}px`},
                { width: `${width}px`,  height: `${height}px`, left: `${0}px`, top: `${0}px`},
            ]
            const params = { duration: animDuration, easing: 'cubic-bezier(0,.27,.62,1)'};
            contentElement.animate(keyFrames, params);
        } else{
            contentElement.style.left = left;
            contentElement.style.top = top;
            const keyFrames = [
                { width: `${rect.width}px`,  height: `${rect.height}px`, left: `${rect.x}px`, top: `${rect.y}px`},
                { width: `${700}px`,  height: `${500}px`, left: `${left}px`, top: `${top}px`},
            ]
            const params = { duration: animDuration, fill:'forwards', easing: 'cubic-bezier(0,.1,.42,1)'};
            contentElement.animate(keyFrames, params);
        }
    }
    
    const AnimateOut = () =>{
        const contentElement = contentRef.current;
        const uiControlsElement = uiControlsRef.current;
        const rootElement = rootRef.current;
        const width = window.innerWidth;
        const height = window.innerHeight;
        const left = (width - 700) / 2;
        const top = (height - 500) / 2;
        uiControlsElement.style.display = 'none';
        contentElement.classList.remove(styles.content_normal);
        setTimeout(()=>{
            contentElement.style.display = 'none';
        },animDuration - 10)
        rootElement.style.animation = `${styles.bg_show} 1s ease-out reverse backwards`;
        
        if (width < 741){
            const keyFrames = [
                { width: `${width}px`,  height: `${height}px`, left: `${0}px`, top: `${0}px`},
                { width: `${rect.width}px`,  height: `${rect.height}px`, left: `${rect.x}px`, top: `${rect.y}px`},
            ]
            const params = { duration: animDuration, easing: 'cubic-bezier(0,.27,.62,1)'};
            contentElement.animate(keyFrames, params);
        } else{
            contentElement.style.left = left;
            contentElement.style.top = top;
            const keyFrames = [
                { width: `${700}px`,  height: `${500}px`, left: `${left}px`, top: `${top}px`},
                { width: `${rect.width}px`,  height: `${rect.height}px`, left: `${rect.x}px`, top: `${rect.y}px`},
            ]
            const params = { duration: animDuration, fill: 'backwards', easing: 'cubic-bezier(0,.1,.42,1)'};
            contentElement.animate(keyFrames, params);
        }
    }




    const imageBlock = () => {
        if (product.imageUrl == null){
            return (
                <img src="/images/photo_placeholder.png" style={{height:'200px'}} alt='ph'></img>
            )
        } else{
            return(
                <img src={(loaded ? '/api/img/' : '/api/img/thmb/') + product.imageUrl} alt={product.imageUrl} onError={({ currentTarget })  => {
                    currentTarget.onerror = null; 
                    currentTarget.src="/images/photo_placeholder_big.png.png";
                }}></img>
            )
        }
    }

    const showIngredients = () =>{
        const infoPanel = document.getElementById('product-info-panel');
        const ingPanel = document.getElementById('product-ingredients-panel');
        ingPanel.style.visibility = 'visible'
        setTimeout(()=>{
            infoPanel.style.animation = null;
            ingPanel.style.animation = null;
            infoPanel.style.visibility = 'hidden';
            setIngredientSelection(true);
        }, 500)
        infoPanel.style.animation = `${styles.right_to_left_hide} 0.5s ease-out both`;
        ingPanel.style.animation = `${styles.right_to_left_show} 0.5s ease-out both`;
    }

    const hideIngredients = () =>{
        const infoPanel = document.getElementById('product-info-panel');
        const ingPanel = document.getElementById('product-ingredients-panel');
        infoPanel.style.visibility = 'visible';
        
        setTimeout(()=>{
            infoPanel.style.animation = null;
            ingPanel.style.animation = null;
            ingPanel.style.visibility = 'hidden'
            setIngredientSelection(false);
        }, 500)
        infoPanel.style.animation = `${styles.right_to_left_hide} 0.5s ease-in both reverse`;
        ingPanel.style.animation = `${styles.right_to_left_show} 0.5s ease-in both reverse`;
    }



    const buttonAddClick = async(withModifiers) => {
        try{
            if (withModifiers){
                const productRow = {
                    product_id: product.id,
                    quantity: 1,
                }
                var ingredients = [];
                selectedIngredients.forEach(x => {
                    const ingredient = {
                        ingredient_id: x.id,
                        quantity: x.quantity
                    }
                    ingredients.push(ingredient);
                });
                productRow.ingredients = ingredients;
                const result = await deliveryStore.AddToCart(productRow);
                if (result === true){
                    Close();
                }



            } else{
                if ((product.ingredients && product.ingredients.length > 0) || (product.ingredientsGroups && product.ingredientsGroups.length > 0)){
                    showIngredients();
                } else{
                    onAddClick(product, null);
                }
            }
            
            
        }catch(e){
            console.error(e)
        }
        
    }

    const cartMinusClick = (e) =>{
        e.stopPropagation();
        onMinusClick(product);
    }

    const cartPlusClick = (e) =>{
        e.stopPropagation();
        onPlusClick(product);
    }

    ///Кнопка корзина
    const CartButton = () =>{
        const product_in_cart = deliveryStore.GetProductInCart(product.id);
        if (product.ingredients == null && product.ingredientsGroups == null){
            
            if (product_in_cart == null){
                return(
                    <button className={styles.button_add} onClick={()=>buttonAddClick(false)}>ДОБАВИТЬ</button>
                )
            } else{
                return(
                    <div className={styles.cart_buttons}> 
                        <button onClick={(e) => cartMinusClick(e)}>-</button>
                        <p onClick={(e) => {e.stopPropagation()}}>{product_in_cart.quantity}</p>
                        <button onClick={(e) => cartPlusClick(e)}>+</button>
                    </div>
                )
            }
        } else{
            if (product_in_cart == null){
                return(
                    <button className={styles.button_add} onClick={()=>buttonAddClick(false)}>ДОБАВИТЬ</button>
                )
            }
            return(
                <button className={styles.button_add} onClick={()=>buttonAddClick(false)}>ДОБАВИТЬ</button>
            )
        }
    }


    const infoPanel = () => {
        return(
            <div id='product-info-panel' className={styles.info_panel}>
                <div className={styles.price_buttons_panel}>
                    <h2 >{product.price}<span>₽</span></h2>
                    { CartButton() }
                </div>
                <div className={styles.additional_info}>
                    { product.weight == null || product.weight === '' ? '' : 
                        <span className={styles.weight}>
                            <img src='/images/controls/weight_icon.png' alt='weight'></img> 
                            <p>{product.weight} гр.</p>
                        </span> }
                    { product.energyValue == null || product.energyValue === '' ? '' : 
                        <span className={styles.energy_value}>
                            <img src='/images/controls/energy_value_icon.png' alt='energy-value'></img> 
                            <p>{product.energyValue } ккал</p>
                        </span> }
                </div>
                <h1>{product.name}</h1>
                <p>{product.description}</p>
            </div>
        )
    }
    

    const ingredientPanel = () => {
        if (product.ingredients === undefined && product.ingredientsGroups === undefined){
            console.log('Нет ингредиентов')
            return
        }
            
        return(
            <div id='product-ingredients-panel' className={styles.ingredients_panel}>
                <div className={styles.ingredients_head}>
                    <button className={styles.button_back} onClick={()=> hideIngredients()}>
                        <img src='/images/controls/ic_arrow_back.png' alt='back'></img>
                    </button>
                    <h1>Варианты</h1>
                    <button className={styles.button_add} onClick={()=>buttonAddClick(true)}>ДОБАВИТЬ</button>
                </div>
                <div className={styles.ingredients_container}>
                    <div className={styles.ingredients}>
                    {
                        product.ingredients == null  ? '' :
                        <div className={styles.ingredients_group_root}>
                            <h1>Ингредиенты</h1>
                            <div className={styles.ingredients_list}>
                            {
                                product.ingredients.map(ing => ingredient(ing, null))
                            }                                  
                            </div>
                        </div>
                    }
                    </div> 
                    {
                        product.ingredientsGroups === null || product.ingredientsGroups.length === 0 ? "" :
                        product.ingredientsGroups.map((ingGroup, i) => 
                        {
                            return(
                                <div key={ingGroup.name} className={styles.ingredients_group_root}>
                                    <h1>{ingGroup.name}</h1>
                                    
                                    <div className={styles.ingredients_list}>
                                        {
                                            ingGroup.ingredients.map(ing => ingredient(ing, i))
                                        }                                  
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        )
    }

    const ingQuantityMinus = (e, ingredient) =>{
        e.stopPropagation();
        const selectedIngredient = selectedIngredients.find(x => x.id === ingredient.id);
        if (selectedIngredient !== undefined){
            var newState = selectedIngredients;
            if (selectedIngredient.quantity === 1){
                newState = newState.filter(x => x.id !== ingredient.id)
            } else{
                newState = selectedIngredients.map( x => {
                    if (x.id === ingredient.id){
                       return {...x, quantity : x.quantity -= 1}
                    }
                    return x;
                })
            }
            setSelectedIngredients(newState)
        }
    }

    const ingQuantityPlus = (e, ingredient) =>{
        e.stopPropagation();
        const selectedIngredient = selectedIngredients.find(x => x.id === ingredient.id);
        if (selectedIngredient !== undefined){
            var newState = selectedIngredients;
             newState = selectedIngredients.map( x => {
                if (x.id === ingredient.id){
                    return {...x, quantity : x.quantity += 1}
                }
                return x;
            })
            setSelectedIngredients(newState)
        }

    }

    const ingredient = (ingredient, group_id) =>{
        
        const selectedIngredient = selectedIngredients.find(x => x.id === ingredient.id);
        var isSelected = selectedIngredient !== undefined;
        const classNames = `${styles.ingredient_root} ${ isSelected ? styles.ingredient_root_selected : ''}`;
        return(
            <div id={ingredient.id} key={ingredient.id} className={classNames} onClick={()=>ingredientClick(ingredient, group_id)}>
                <h2>{ ingredient.name}</h2>
                {
                    group_id != null || !isSelected ? '' :
                    <div className={styles.ingredient_quantity}>
                        <button onClick={(e) => ingQuantityMinus(e, ingredient)}>-</button>
                        <p onClick={(e) => {e.stopPropagation()}}>{selectedIngredient.quantity}</p>
                        <button onClick={(e) => ingQuantityPlus(e, ingredient)}>+</button>
                    </div>
                }
                { ingredient.price && ingredient.price > 0 ? <h3>{ ingredient.price}<span>₽</span></h3> : '' }
            </div>
        )
    }

    const ingredientClick = (ingredient, group_id) =>{
        var selIngList = selectedIngredients;
        var ing = selIngList.find(x=> x.id === ingredient.id);
        if (ing !== null && ing !== undefined){
            setSelectedIngredients(selIngList.filter(x => x.id !== ingredient.id));
        } else{
            const newIng = {id: ingredient.id, quantity:  1, group_id: group_id };
            if (group_id != null){
                setSelectedIngredients(
                    [...selIngList.filter(x=>x.group_id !== group_id), 
                        newIng]);
            } else{
                setSelectedIngredients(
                    [...selIngList, 
                        newIng]);
            }
            }
    }



    return (
        <div  ref={rootRef} {...otherProps}  className={styles.root} onClick={() => Close()}>
            <div ref={contentRef} className={styles.content}  onClick={(e)=> e.stopPropagation()}>
                <div className={styles.image_thmb}>
                    { imageBlock() }
                    
                </div>
                <div ref={uiControlsRef} className={styles.ui_controls}>
                    <div className={styles.main_gradient}></div>
                    <button className={styles.close} onClick={() => Close()}>
                        <img src='/images/controls/ic_close_1.png' alt='close'></img>
                    </button>
                    {/*  INFO PANEL */}
                    { ingredientPanel()}
                    { infoPanel()}
                    {/* INGREDIENTS PANEL */}
                </div>
                
                
            </div>
        </div>
    );
};

DeliveryProduct.defaultProps = {
    product : null,
    rect : null,
    onClose : ()=>{},
    onAddClick : () => {},
    onPlusClick : () => {},
    onMinusClick : () => {},
}

DeliveryProduct.propTypes = {
    product : PropTypes.object,
    rect : PropTypes.object,
    onClose : PropTypes.func,
    onAddClick : PropTypes.func,
    onPlusClick : PropTypes.func,
    onMinusClick : PropTypes.func,
}

export default DeliveryProduct;