import { useLayoutEffect, useState } from "react"
import GlobalStore from "../stores/GlobalStore";

const isDarkTheme = window.matchMedia('(prefers-color-scheme: dark)').matches;
const defaultTheme = isDarkTheme ? 'dark' : 'light';

export const useTheme = () => {
    const [theme, setTheme] = useState(localStorage.getItem('app-theme') || defaultTheme)

    useLayoutEffect(()=>{
        document.documentElement.setAttribute('data-theme', theme)
        localStorage.setItem('app-theme', theme)
        GlobalStore.Theme = theme;
    },[theme]);

    return {theme, setTheme}
}