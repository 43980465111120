import React, { useEffect, useRef, useState } from 'react';
import styles from './DeliveryDashboard.module.css'
import styleCategoriesMenu from './delivery/DeliveryCategoriesMenu.module.css'
import styleCartPreview from './delivery/DeliveryCartPreview.module.css'
import { deliveryStore } from '../stores/DeliveryStore';
import { observer } from 'mobx-react-lite';
import DeliveryCategoriesMenu from './delivery/DeliveryCategoriesMenu';
import DeliveryProductListItem from './delivery/DeliveryProductListItem';
// import DeliveryProductViewOLD from './delivery/DeliveryProductViewOLD';
import DeliveryCartPreview from './delivery/DeliveryCartPreview';
import DeliveryProduct from './delivery/DeliveryProduct';

const DeliveryDashboard = observer (() => {

    const scrolling = useRef(false)

    const [categoriesExpand, setCategoriesExpand] = useState(false);
    const [cartExpand, setCartExpand] = useState(false);
    const [showProduct, setShowProduct] = useState(null);
    const [stickyCategory, setStickyCategory] = useState(null);
    
    let observerOptions = {
        threshold: [0, 1],
        rootMargin: '-50px 0px 100px 0px'
    }

    let observerOptions2 = {
        threshold: [0, 1],
        
    }
    const StickyCallbackCategory = (entries)=>{
        entries.forEach((entry) =>{
            try{
                
                if (entry.boundingClientRect.top < 50){
                    if (scrolling.current === false)
                        setStickyCategory(entry.target.parentElement.id);
                    entry.target.classList.add(styles.category_title_root_sticky);
                } else{
                    entry.target.classList.remove(styles.category_title_root_sticky);
                }
            }
            catch{}
            
        });
    }

    const StickyCallbackHead = (entries)=>{
        try{
            const entry = entries[0];
            const head = document.getElementsByClassName(`${styles.head_root}`)[0];
            if (entry.boundingClientRect.top < 0){
                head.classList.add(styles.head_root_sticky);
            } else{
                head.classList.remove(styles.head_root_sticky);
            }
        }
        catch{}
        
    }

    const stickyObserverCategory = new IntersectionObserver(StickyCallbackCategory, observerOptions)
    const stickyObserverHead = new IntersectionObserver(StickyCallbackHead, observerOptions2)

    const applyStickyTrigger = () => {
        try
        {
            const elements = document.querySelectorAll(`.${styles.category_title_root}`);
            elements.forEach((element) => {
                stickyObserverCategory.observe(element);
            })
            const element2 = document.querySelector(`#sticky-trigger`);
            stickyObserverHead.observe(element2)
        }
        catch(error){
            console.error(error)
        }
    }

    const disableStickyTrigger = () => {
        try
        {
            
        }
        catch(error){
            console.error(error)
        }
    }


    useEffect(()=>
        {
            const init = async () =>{
                await getData();
                applyStickyTrigger();
                
            }
            init();
            return(()=>
                {
                    disableStickyTrigger();
                    console.log('delivery_unmount')
                })
    },[])


    const getData = async () =>{
        await deliveryStore.FetchMenu();
        await deliveryStore.GetCart();
        deliveryStore.StopLoading();
        
    }

    

    if (deliveryStore.isLoading === true){
        return (
            <div className={styles.loading_message}> Загрузка...</div>
        )
    }

    const openProduct = (product, rect) =>{
        setShowProduct({product, rect})
    }

    const closeProduct = () => {
        setShowProduct(null)
    }

    //Добавить в карзину 
    const addToCart = async (product, rect) =>{

        if (product.ingredients == null && product.ingredientsGroups == null){
            const productRow = {
                product_id: product.id,
                quantity: 1,
            }
            await deliveryStore.AddToCart(productRow);
        } 
        else
        {
            setShowProduct({product, rect})
            console.log('have ingredients');
        }
    }

    const productMinusClicked = (product) =>{
        var product_cart = deliveryStore.getFirstProductInCart(product.id)
        deliveryStore.minusCart(product_cart.id)
    }

    const productPlusClicked = (product) =>{
        var product_cart = deliveryStore.getFirstProductInCart(product.id)
        deliveryStore.plusCart(product_cart.id)
    }

    

    const products = (categoryId)=>{
        const products = deliveryStore.products.filter(x => x.categoryId === categoryId);
        if (products === null || products.length === 0)
            return (
        <div className={styles.subcategory_title} style={{fontSize:'0.8em'}}>В категории нет блюд</div>
        );
        return(
            <div className={styles.products_container}>
            {
                products.map(product => {
                    return(
                        // <DeliveryProductItem_OLD key={product.id}  product={product}></DeliveryProductItem_OLD>
                        <DeliveryProductListItem key={product.id} 
                                                 product={product}
                                                 onBodyClick={(product, rect) => openProduct(product, rect)}
                                                 onAddClick={(product, rect) => addToCart(product, rect)}
                                                 onPlusClick={() => productPlusClicked(product)}
                                                 onMinusClick={() => productMinusClicked(product)}
                                                 ></DeliveryProductListItem>
                    )
                })
            }
            </div>
        )
    }

    
    const ExpandCategoriesMenu = () =>{
        if (categoriesExpand){
            expandMenuOff()
        } else{
            setCategoriesExpand(true)
        }
    }

    const expandMenuOff = () =>{
        const menuExpandBg  = document.getElementById('menu-expand-bg');
        const menuExpand = document.getElementsByClassName(styleCategoriesMenu.root)[0];
        menuExpand.style.animation = `${styleCategoriesMenu.unexpand_menu} .3s ease-out`;
        menuExpandBg.style.animation = `${styles.hide_bg} .3s ease-out`;
        menuExpand.style.animationFillMode = 'forwards';
        menuExpandBg.style.animationFillMode = 'forwards';
        setTimeout(() => {
            setCategoriesExpand(false)   
        }, 300);
    }

    const ExpandCart = () =>{
        if (cartExpand){
            expandCartOff()
        } else{
            setCartExpand(true)
        }
    }

    const expandCartOff = () =>{
        
        const cartExpandBg  = document.getElementById('cart-expand-bg');
        const cartExpand = document.getElementsByClassName(styleCartPreview.root)[0];
        cartExpand.style.animation = `${styleCartPreview.hide} .3s ease-out`;
        cartExpand.style.animationFillMode = 'forwards';
        cartExpandBg.style.animation = `${styles.hide_bg} .3s ease-out`;
        cartExpandBg.style.animationFillMode = 'forwards';
        setTimeout(() => {
            setCartExpand(false)   
        }, 300);
    }
    
    const categoryClicked = (category_id, hide_menu, index) =>{    
        if (hide_menu){
            expandMenuOff();
        }
        setStickyCategory(category_id)
        scrolling.current = true;
        document.onscrollend = event =>{
            scrolling.current = false;
        }
        const element = document.getElementById(category_id);
        var top = 0;
        if (index !== 0){
            top = element.getBoundingClientRect().top + window.scrollY -60;
        }
        window.scrollTo({
            top: top,
            behavior: 'smooth'
        })
    }

    const desktopCategories =()=>{
        return(
            <div className={styles.desktop_categories_root}>
                {
                    deliveryStore.categories.filter(x=> x.parentId == null).map((category, index) => {
                        var sticky = false;
                        if (category.id === stickyCategory || (stickyCategory === null && index === 0)){
                            sticky = true;
                        }
                        return(
                            <div key={category.id} onClick={() => categoryClicked(category.id, false, index)}
                                className={`${styles.desktop_category} ${sticky ? styles.desktop_category_sticky : ''}` }>{category.name}</div>
                        )
                    })
                }
            </div>
        )
    }

    const head = () =>{
        const menuStyle = categoriesExpand ? { zIndex:'20' } : {zIndex:'1'};
        const cartStyle = cartExpand ? { zIndex:'20' } : {zIndex:'1'};
        
        return (
            <div className={styles.head_root}>
                {/* Кнопка меню */}
                <div className={styles.head_categories} 
                    onClick={ExpandCategoriesMenu} style={menuStyle}>МЕНЮ</div>
                {/* Блок меню  */}
                { !categoriesExpand ? '' :
                    <div>
                        <div className={styles.menu_expand_head_bg}></div>
                        <div id='menu-expand-bg' className={styles.expand_bg} onClick={expandMenuOff}></div>
                        <DeliveryCategoriesMenu onSelected={(category_id, index) => categoryClicked(category_id, true, index)} ></DeliveryCategoriesMenu>
                    </div>
                }   
                <div className={styles.head_center}></div>
                {/* Корзина */}
                <div className={styles.head_cart} 
                    onClick={ExpandCart} style={cartStyle}> {deliveryStore.GetCartSum() + ' ₽'} </div>
                {/* Блок корзины */}
                {
                    !cartExpand ? '' :
                    <div>
                        <div id='cart-expand-bg' className={styles.expand_bg}  onClick={expandCartOff} ></div>
                        <DeliveryCartPreview></DeliveryCartPreview>
                    </div>
                }
            </div>
        )
    }


    return (
        <div id='d-root' className={styles.root}>
            <div id='sticky-trigger' style={{width:'1px', height:'1px'}} ></div>
            {
                showProduct == null ? '' :
                // <DeliveryProductViewOLD 
                //     isActive={true} 
                //     product={showProduct}
                //     onClose={closeProduct}
                //     ></DeliveryProductViewOLD>
                <DeliveryProduct 
                    onClose={() => closeProduct()} 
                    product={showProduct.product} 
                    rect={showProduct.rect} 
                    onAddClick={(product, rect) => addToCart(product, rect)}
                    onPlusClick={(product) => productPlusClicked(product)}
                    onMinusClick={(product) => productMinusClicked(product)}>
                    </DeliveryProduct>
            }
            { desktopCategories() }
            <div className={styles.list_container}>
                { head() }
                {
                    deliveryStore.categories.filter(x=>x.parentId == null).map((category) => {
                        return (
                            <div id={category.id} key={category.id} className={styles.category_container}>
                                <div id={'cat-title-root'}  className={styles.category_title_root} style={{zIndex:categoriesExpand || cartExpand ? '1' : '3'}}>
                                    <div className={styles.category_title}>{category.name} </div>
                                </div>
                                { products(category.id) }
                                { 
                                    deliveryStore.categories.filter(x => x.parentId === category.id).map((subcategory)=>{
                                    return(
                                        <div id={subcategory.id}  key={subcategory.id} >
                                            <div className={styles.subcategory_title}>{subcategory.name}</div>
                                            { products(subcategory.id) }
                                        </div>
                                    )
                                })}
                            </div>
                        )
                    })
                }
            </div>
        </div>
    );
});

export default DeliveryDashboard;