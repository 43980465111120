import React from 'react';
import styles from './CookieMessage.module.css'
import { useState } from 'react';


const CookieMessage = props => {

    const [accepted, setAccepted] = useState(localStorage.getItem('cookie_accepted'));

    const closeClick = ()=>{
        localStorage.setItem('cookie_accepted', 1)
        setAccepted('1');
    }

    return (
        <div className={styles.root} style={{visibility:accepted === '1' ? 'hidden' : 'visible'} }>
            <div className={styles.content}>
                
                <h3>ВЕБСАЙТ НАХОДИТСЯ В РАЗРАБОТКЕ, БОЛЬШАЯ ЧАСТЬ ФУНКЦИОНАЛА В ДАННЫЙ МОМЕНТ НЕДОСТУПНА</h3>
                <h1>Соглашение на использование cookie</h1>
                <h2>{`Cайт использует файлы cookie и сервисы сбора технических данных посетителей 
                     для идентификации пользователей. Продолжая использовать наш сайт, 
                    вы автоматически соглашаетесь с использованием данных технологий.`}</h2>
                <button className={styles.close} onClick={closeClick}>
                    <img src='images/controls/ic_close_1.png' alt='close'></img>
                </button>
            </div>
        </div>
    );
};

export default CookieMessage;