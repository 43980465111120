import React, { useRef } from 'react';
import styles from './NavigationMenu.module.css'
import { NavLink } from 'react-router-dom';
import { useState } from 'react';
import NavigationItem from './NavigationItem';
import { observer } from 'mobx-react-lite';
import { useTheme } from '../../hooks/useTheme';

const NavigationMenu = observer(() => {
    
    const [expanded, setExpanded] = useState(false)
    const {theme, setTheme} = useTheme();
    const [darkTheme, setDarkTheme] = useState(theme === 'dark')
   
    const themeRef = useRef();
    const logoImgRef = useRef();
    const expandClick = (e)=>{
        if (expanded){
            expandOff();
        } else{
            expandOn();
        }
    }

    const items = [
        {
            to: "/",
            text: "Главная",
            always: true,
            separator : true,
        },
        {
            to: "/",
            always: true,
            text: "Меню",
            separator : false,
        },
        {
            to: "/delivery",
            always: true,
            text: "Доставка",
            separator : false,
        },
        {
            to: "/",
            always: false,
            text: "Бронь  столика",
            separator : false,
        },
        {
            to: "/",
            text: "Кейтеринг",
            always: false,
            separator : true,
        },
        {
            to: "/loyalty",
            always: false,
            text: "Программа лояльности",
            separator : false,
        }
    ]
    
    const switchTheme = () =>{
        themeRef.current.style.animation = `${styles.theme_switch} .6s both`;
        logoImgRef.current.style.animation = `${styles.theme_switch_logo} .3s both`;
        setTimeout(()=>{
            themeRef.current.style.animation = 'none';
            logoImgRef.current.style.animation = 'none';
        }, 600)

        if (theme === 'dark'){
            setTheme('light');
            setTimeout(()=>{
                setDarkTheme(false);
            }, 150)
        } else{
            setTheme('dark');
            setTimeout(()=>{
                setDarkTheme(true);
            }, 150)
        }
        
        
    }

    const expandOff = () =>{
        const expanded_links = document.getElementById('nav_menu_expanded_links');
        if (expanded_links == null)
            return;
        const line1 = document.getElementById('menu_expand_line1');
        const line2 = document.getElementById('menu_expand_line2');
        const line3 = document.getElementById('menu_expand_line3');
        const expand_button = document.getElementById('menu_expand_button');
        const background = document.getElementById('nav_expand_background');
        background.style.animation = `${styles.hide_bg} .3s ease-out`;
        background.style.animationFillMode = 'forwards';

        expand_button.style.animation = `${styles.button_expand_hide} .3s ease-out`;
        expand_button.style.animationFillMode = 'forwards';
        line1.style.animation = `${styles.unexpand_line1} .3s ease-out`;
        line1.style.animationFillMode = 'forwards';
        
        line2.style.animation = `${styles.unexpand_line2} .3s ease-out`;
        line2.style.animationFillMode = 'forwards';
        line3.style.animation = `${styles.unexpand_line3} .3s ease-out`;
        line3.style.animationFillMode = 'forwards';
        
        expanded_links.style.animation = `${styles.unexpand_menu} .3s ease-out`;
        expanded_links.style.animationFillMode = 'forwards';
        setTimeout(() => {
            line1.style.animation = null;
            line2.style.animation = null;
            line3.style.animation = null;
            expand_button.style.animation = null;
            setExpanded(false);    
        }, 300);
        
    }

    const expandOn = () =>{
        const line1 = document.getElementById('menu_expand_line1');
        const line2 = document.getElementById('menu_expand_line2');
        const line3 = document.getElementById('menu_expand_line3');
        const expand_button = document.getElementById('menu_expand_button');
        expand_button.style.animation = `${styles.button_expand_show} .3s ease-out`;
        expand_button.style.animationFillMode = 'forwards';
        line1.style.animation = `${styles.expand_line1} .3s ease-out`;
        line1.style.animationFillMode = 'forwards';
        line2.style.animation = `${styles.expand_line2} .3s ease-out`;
        line2.style.animationFillMode = 'forwards';
        line3.style.animation = `${styles.expand_line5} .3s ease-out`;
        console.log(styles.expand_line5);
        line3.style.animationFillMode = 'forwards';
        
        setExpanded(true);
    }


    const links = (expanded_mode) =>{
        return (
            <div id={expanded_mode ? 'nav_menu_expanded_links' : 'nav_menu_links' }  className={expanded_mode ? styles.links_expanded : styles.links}>
                {
                    items.map((item, ind) =>{
                        if (expanded_mode || (expanded === false && item.always )){
                            const index = ind + 1;
                            return(
                                <NavigationItem aria-current="page" navTo={item.to}
                                    key={index} id={index} text={item.text}
                                    expandMode={expanded_mode}
                                    separator={item.separator}
                                    onClick={ expanded_mode ? expandOff : null}/>
                            )
                        }  else{
                            return null;
                        }
                    })
                }
            </div>
        )
    }

    

    return (
        <nav className={styles.root} style={{zIndex: expanded ? '100' : '3'}}>
            {expanded ? <div id='nav_expand_background' className={styles.background} onClick={expandOff}></div> : '' }
            
            <div className={styles.head} style={{background: expanded ?'var(--navbar-bg-color)' : ''}}>
                { expanded ? links(true) : '' }

                <NavLink to={"/"}>
                    <div className={styles.logo}>
                        <img ref={logoImgRef} alt='theme' src={!darkTheme ? '/images/varvara/logo_dark.png' : 
                                                    '/images/varvara/logo_light.png' }
                        />
                    </div>
                </NavLink>
                <div className={styles.information_panel}>
                    <div className={styles.info_address}>ул. Советская, 28</div>
                    <a href='tel:+74942471931' className={styles.info_phone}>47-19-31</a>
                </div>
                <div className={styles.head_content}>
                    { links(false) }
                </div>
                <div ref={themeRef} className={styles.themeSwitcher} onClick={switchTheme}>
                    <img alt='theme' src={!darkTheme ? '/images/controls/dark_theme.png' : 
                                                 '/images/controls/light_theme.png'}
                    />
                </div>
                <button id='menu_expand_button' className={styles.expand_button} onClick={(e)=> expandClick(e)}>
                    <div id='menu_expand_line1' className={styles.expand_button_line}></div>
                    <div id='menu_expand_line2'  className={styles.expand_button_line}></div>
                    <div id='menu_expand_line3' className={styles.expand_button_line}></div>
                </button>
            </div>
            
            
        </nav>
    );
});

export default NavigationMenu;