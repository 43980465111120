import React from 'react';
import styles from './DeliveryCategoriesMenu.module.css'
import { observer } from 'mobx-react-lite';
import { deliveryStore } from '../../stores/DeliveryStore';
import PropTypes from 'prop-types';

const DeliveryCategoriesMenu = observer((props) => {

    const {onSelected, ...otherProps } = props;

    const categorySelected = (category_id, index) =>{
        onSelected(category_id, index);
    }

    return (
        <div className={styles.root} {...otherProps}> 
            <div className={styles.categories_list}>
                { deliveryStore.categories.filter(x=>x.parentId == null).map((cat, index) => {
                    return(
                        <div key={cat.id} className={styles.category_root}>
                            <div className={styles.category_title} onClick={()=> categorySelected(cat.id, index)}>{cat.name}</div>
                            {
                                // deliveryStore.categories.filter(x => x.parentId === cat.id).map((subcat)=>{
                                //     return(
                                //         <div key={subcat.id} className={styles.subcategories_root}>
                                //             <div className={styles.subcategory_title}>{subcat.name}</div>
                                //         </div>
                                //     )
                                // })
                            }
                        </div>
                        
                    );
                })}

            </div>
        </div>
    );
});

DeliveryCategoriesMenu.defaultProps ={
    onSelected : ()=>{}
}

DeliveryCategoriesMenu.propTypes = {
    onSelected : PropTypes.func,
}

export default DeliveryCategoriesMenu;