
import React, { useEffect} from 'react';
import styles from './home.module.css'
import carousel from '../CSS/HomeCarousel.module.css'

const Home = () => {
    const interval = 7000; //ms
    const animation_duration = 2000; //ms
    const carouselImages = ['images/varvara/varvara-c1.jpg', 'images/varvara/varvara-c2.jpg', 'images/varvara/varvara-c3.jpg',
                            'images/varvara/varvara-c4.jpg', 'images/varvara/varvara-c5.jpg', 'images/varvara/varvara-c6.jpg']

    var current_slide = 1;
    var slide = 0;
    var animate_carousel = true;

    useEffect(()=>{
        beginSlideshow();
        return (()=>{
            animate_carousel = false;
        });
    },[])

    

    const beginSlideshow = () => {
        setTimeout(() => 
        {
            if (animate_carousel){
                NextSlide();
                beginSlideshow();
            }
        }, interval);
        
    }

    const NextSlide = () => {
        const slide1 = document.getElementById('home_carousel_slide1');
        const slide2 = document.getElementById('home_carousel_slide2');
        const anim = `${carousel.ShowSlide} ${animation_duration}ms`;
        const nextImage = getNextImage();
        if (current_slide === 1)
        {
            slide2.src = nextImage;
            slide2.style.animation = anim;
            slide2.style.zIndex = 2;
            slide1.style.zIndex = 1;
            setTimeout(()=>{
                slide2.style.animation = null;
                current_slide = 2;
            }, animation_duration);
            
        }
        else
        {
            slide1.src = nextImage;
            slide1.style.animation = anim;
            slide1.style.zIndex = 2;
            slide2.style.zIndex = 1;
            setTimeout(()=>{
                slide1.style.animation = null;
                current_slide = 1;
            }, animation_duration);
            
        }
    }

    const getNextImage = ()=>{
        const length = carouselImages.length;
        const nextIndex = slide + 1;
        if (nextIndex >= length){
            const result = carouselImages[0]
            slide = 0;
            return result;
        } else{
            const result = carouselImages[nextIndex]
            slide = nextIndex;
            return result;
        }
    }


    return(
        <div className={styles.root}>
            <div className={styles.carousel_root}>
                
                <img src='../images/varvara/logo_white.png' className={styles.logo} alt={"logo"}/>
                {/* <div className={styles.menu_button}>
                    <a className={styles.menu_url} href='/varvara/varvara_menu_2020.pdf'>Меню</a>
                </div> */}
                <div className={styles.slides}>
                    <img src={carouselImages[slide]} id='home_carousel_slide1' alt='slide' className={styles.carousel_slide}></img>
                    <img src={carouselImages[slide]} id='home_carousel_slide2' alt='slide' className={styles.carousel_slide}></img>
                </div>
                
                <div className={styles.top_gradient}></div>
                <div className={styles.carousel_gradient}></div>
            </div>
        </div>
    )
};

export default Home;

